import { Brand, BrandUser } from "@kalecard/common";
import { createContext, useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import { auth } from "../config/firebase";
import { localStorageService, LocalStorageKey } from "@kalecard/common";
import { logUser } from "@kalecard/common";
import { BRAND, BRAND_USER, REFRESH_PAYMENT_METHODS } from "../graphql/queries";
import apolloClient from "../config/apolloClient";

export const UserContext = createContext<{
  user: firebase.default.User | null;
  userRecord: BrandUser;
  brandId: string | null;
  brandRecord: Brand | null;
  setBrandId: React.Dispatch<React.SetStateAction<string>>;
  updateBrandRecord: (brandId: string) => Promise<void>;
  refreshPaymentMethods: (brandId: string) => Promise<void>;
}>(null);

const UserProvider = ({ children }: any) => {
  const [user, setUser] = useState<firebase.default.User | null>(undefined);
  const [userRecord, setUserRecord] = useState<BrandUser | null>(null);
  const [brandId, setStateBrandId] = useState<string | null>(null);
  const [brandRecord, setBrandRecord] = useState<Brand | null>(null);
  const [__, _, removeCookie] = useCookies(["session_token"]);

  const setBrandId = (newBrandId: string) => {
    if (newBrandId) {
      localStorage.setItem("brandId", newBrandId);
      setStateBrandId(newBrandId);
      updateBrandRecord(newBrandId);
    }
  };

  const updateBrandRecord = async (brandId: string) => {
    if (brandId != null) {
      const result = await apolloClient.query({
        query: BRAND,
        fetchPolicy: "network-only",
        variables: {
          id: brandId,
        },
      });
      setBrandRecord(result.data.brand as Brand);
    } else {
      setBrandRecord(null);
    }
  };

  const refreshPaymentMethods = async (brandId: string) => {
    const result = await apolloClient.query({
      query: REFRESH_PAYMENT_METHODS,
      fetchPolicy: "network-only",
      variables: {
        id: brandId,
      },
    });
    setBrandRecord({
      ...brandRecord,
      paymentMethods: (result.data.brand as Brand).paymentMethods,
    });
  };

  async function getBrandUser(userId: string) {
    const result = await apolloClient.query({
      query: BRAND_USER,
      fetchPolicy: "network-only",
      variables: {
        userId,
      },
    });
    if (result.data.brandUser == null) {
      // During signup there can be a race condition between the mapping of the brand user id to the firebase user id
      // and the creation of the brand user record. If the brand user record is not found, we wait 3 seconds and try again.
      await new Promise((r) => setTimeout(r, 3000));
      return await apolloClient.query({
        query: BRAND_USER,
        fetchPolicy: "network-only",
        variables: {
          userId,
        },
      });
    } else {
      return result;
    }
  }

  useEffect(() => {
    const unsub = auth.onAuthStateChanged(async (userAuth) => {
      if (userAuth) {
        logUser(userAuth.uid, {
          email: userAuth.email,
        });
        localStorageService.setItem(LocalStorageKey.AUTHENTICATED, "true");
        try {
          const result = await getBrandUser(userAuth.uid);
          setUserRecord(result.data.brandUser as BrandUser);
          const localBrandId = localStorage.getItem("brandId");
          if (localBrandId) {
            setBrandId(localBrandId);
            updateBrandRecord(localBrandId);
          } else {
            const newBrandId =
              result.data.brandUser?.administeredBrands?.length > 0
                ? result.data.brandUser?.administeredBrands[0].brand?.id
                : null;
            setBrandId(newBrandId);
            updateBrandRecord(newBrandId);
          }
        } catch (error) {
          console.error(error);
        }
      } else {
        localStorageService.setItem(LocalStorageKey.AUTHENTICATED, "false");
        localStorage.removeItem("brandId");
        removeCookie("session_token");
      }
      setUser(userAuth);
    });
    return () => {
      localStorageService.setItem(LocalStorageKey.AUTHENTICATED, "false");
      unsub();
    };
  }, []);

  return (
    <UserContext.Provider
      value={{
        user,
        userRecord,
        brandId,
        brandRecord,
        setBrandId,
        updateBrandRecord,
        refreshPaymentMethods,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

export default UserProvider;
