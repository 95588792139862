import { LandingPageVideo, Loading } from "@kalecard/common";
import { Dispatch, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { upload } from "../../utils/upload";

interface FormInput {
  videoFiles?: File[];
  username?: string;
  externalUrl?: string;
}

export interface BrandLandingPageVideoInput {
  username?: string;
  externalUrl?: string;
  bucketPath?: string;
}

export default function UploadLandingPageVideo({
  brandId,
  landingPageVideo,
  setValues,
}: {
  brandId: string;
  landingPageVideo?: LandingPageVideo;
  setValues?: Dispatch<React.SetStateAction<BrandLandingPageVideoInput>>;
}) {
  const [url, setUrl] = useState<string>(landingPageVideo?.url);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { register, watch } = useForm<FormInput>({
    defaultValues: {
      username: landingPageVideo?.username,
      externalUrl: landingPageVideo?.externalUrl,
    },
  });

  useEffect(() => {
    if (landingPageVideo) {
      setValues((prevData) => {
        return {
          ...prevData,
          bucketPath: getBucketPath(landingPageVideo?.url),
          username: landingPageVideo?.username,
          externalUrl: landingPageVideo?.externalUrl,
        };
      });
    }

    const subscription = watch(async (value, { name, type }) => {
      if (name === "videoFiles") {
        if (
          FileReader && value.videoFiles.length > 0
            ? value.videoFiles[0].name
            : null
        ) {
          setIsLoading(true);
          setUrl(null);
          var fr = new FileReader();
          fr.readAsDataURL(value.videoFiles[0]);

          var videoUploadResult = null;
          if (value.videoFiles.length > 0) {
            videoUploadResult = await upload(
              value.videoFiles[0],
              "BRAND_LANDING_PAGE_VIDEO",
              {
                data: [{ key: "brandId", value: brandId }],
              },
              brandId,
              () => {
                setIsLoading(false);
                setUrl(String(fr.result));
              }
            );
            setValues((prevData) => {
              return {
                ...prevData,
                bucketPath: videoUploadResult.bucketPath,
              };
            });
          }
        }
      } else {
        setValues((prevData) => {
          return {
            ...prevData,
            username: value.username,
            externalUrl: value.externalUrl,
          };
        });
      }
    });
    return () => {
      subscription.unsubscribe();
    };
  }, []);

  return (
    <div className="flex flex-col items-center justify-center">
      <div
        className={`flex h-[473px] w-[266px] items-center justify-center ${
          !url && "rounded-md border border-dashed"
        }`}
      >
        {url ? (
          <video
            controls
            key={url}
            autoPlay={true}
            loop={true}
            muted={true}
            playsInline={true}
            src={url}
            controlsList="nodownload"
            onContextMenu={(e) => e.preventDefault()}
            onDragStart={(e) => e.preventDefault()}
            onError={(e) =>
              ((e.target as HTMLVideoElement).style.display = "none")
            }
            className="flex:1 h-full w-full rounded-2xl object-cover p-2"
          ></video>
        ) : isLoading ? (
          <div className="flex flex-col">
            <Loading />
            <p>Uploading...</p>
          </div>
        ) : (
          <>
            <label className="cursor-pointer rounded-md border border-gray-300 bg-white py-2 px-3 text-sm font-medium leading-4 text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">
              <span className="text-base leading-normal">Select video</span>
              <input
                className="hidden"
                {...register("videoFiles")}
                accept="video/*"
                type="file"
                name="videoFiles"
                disabled={isLoading}
              />
            </label>
          </>
        )}
      </div>
      {url && (
        <label className="cursor-pointer rounded-md border border-gray-300 bg-white py-2 px-3 text-sm font-medium leading-4 text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">
          <span className="text-base leading-normal">Select video</span>
          <input
            className="hidden"
            {...register("videoFiles")}
            accept="video/*"
            type="file"
            name="videoFiles"
            disabled={isLoading}
          />
        </label>
      )}
      <div className="w-full">
        <div className="sm:col-span-3">
          <label
            htmlFor="username"
            className="block pt-1 text-sm font-medium text-gray-700"
          >
            Username
          </label>
          <div className="mt-1 flex rounded-md shadow-sm">
            <span className="inline-flex items-center rounded-l-md border border-r-0 border-gray-300 bg-gray-50 px-3 text-gray-500 sm:text-sm">
              @
            </span>
            <input
              type="text"
              {...register("username")}
              name="username"
              id="username"
              className="block w-full min-w-0 flex-1 rounded-none rounded-r-md border-gray-300 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
            />
          </div>
        </div>
        <div className="sm:col-span-3">
          <label
            htmlFor="externalUrl"
            className="block pt-1 text-sm font-medium text-gray-700"
          >
            External Url
          </label>
          <div className="relative mt-1 rounded-md shadow-sm">
            <input
              type="text"
              {...register("externalUrl")}
              name="externalUrl"
              id="externalUrl"
              className="block w-full rounded-md border-gray-300 px-3 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
              placeholder="https://www.tiktok.com/@username/video/1235"
            />
          </div>
        </div>
      </div>
    </div>
  );
}

function getBucketPath(url: string): string {
  if (url) {
    let substring = "https://storage.googleapis.com/kale-public/";
    let index = url.indexOf(substring);
    if (index >= 0) {
      let result = url.substring(index + substring.length);
      return result;
    } else {
      return null;
    }
  } else {
    return null;
  }
}
