import { Menu, Transition } from "@headlessui/react";
import { EllipsisVerticalIcon } from "@heroicons/react/24/outline";
import { BrandUser } from "@kalecard/common";
import { Fragment } from "react";

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export default function BrandAdminRow({
  admin,
  isBrandPOC,
  isLoggedInAdmin,
  removeAdmin
}: {
  admin?: BrandUser;
  isBrandPOC: boolean;
  isLoggedInAdmin: boolean;
  removeAdmin?: (admin: BrandUser) => void;
}) {
  return (
    <>
      {admin && (
        <tr>
          <td className="text-wrap py-4 text-sm text-gray-500 px-4">
            <div className="text-gray-900">{admin.email}</div>
          </td>
          <td>
            {admin.createdAt && (<div className="text-gray-500 px-4">{new Date(Number(admin.createdAt)).toLocaleDateString()}</div>)}
          </td>
          {isBrandPOC && (
            <td>
              {!isLoggedInAdmin && (
                <Menu as="div" className="relative inline-block text-left">
                <div>
                  <Menu.Button className="flex items-center rounded-full text-gray-400 hover:text-gray-600 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 focus:ring-offset-gray-100">
                    <span className="sr-only">Open options</span>
                    <EllipsisVerticalIcon className="h-5 w-5" aria-hidden="true" />
                  </Menu.Button>
                </div>

                <Transition
                  as={Fragment}
                  enter="transition ease-out duration-100"
                  enterFrom="transform opacity-0 scale-95"
                  enterTo="transform opacity-100 scale-100"
                  leave="transition ease-in duration-75"
                  leaveFrom="transform opacity-100 scale-100"
                  leaveTo="transform opacity-0 scale-95"
                >
                  <Menu.Items className="absolute right-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                    <div className="py-1">
                      <Menu.Item>
                        {({ active }) => (
                          <a
                            href="#"
                            className={classNames(
                              active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                              'block px-4 py-2 text-sm'
                            )}
                            onClick={() => removeAdmin(admin)}
                          >
                            Remove Access
                          </a>
                        )}
                      </Menu.Item>
                    </div>
                  </Menu.Items>
                </Transition>
              </Menu>
              )}
            </td>
          )}
        </tr>
      )}
    </>
  );
}
