import { useMutation } from "@apollo/client";
import { Message, Modal, Thread, timeDifferenceForDate } from "@kalecard/common";
import { useContext, useEffect, useState } from "react";
import { SEND_BRAND_INSTAGRAM_MESSAGE } from "../../../graphql/mutations";
import { UserContext } from "../../../providers/UserProvider";

interface MessagesProps {
  thread: Thread;
}

export default function Messages({ thread }: MessagesProps) {
  const [localMessages, setLocalMessages] = useState<Map<string, Message[]>>(
    new Map()
  );
  const [response, setResponse] = useState("");
  const [error, setError] = useState(null);
  const { brandId } = useContext(UserContext);
  const [sendMessageMutation] = useMutation(SEND_BRAND_INSTAGRAM_MESSAGE);

  const username = thread.participants[1].username;

  const onChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.currentTarget;
    if (name === "response") {
      setResponse(value);
    }
  };

  const sendMessage = async () => {
    console.log({
      instagramId: thread.participants[0].id,
      recipientId: thread.participants[1].id,
      text: response,
      brandId,
    });
    try {
      const result = await sendMessageMutation({
        variables: {
          instagramId: thread.participants[0].id,
          recipientId: thread.participants[1].id,
          text: response,
          brandId: brandId,
        },
      });
      const oldMessages = localMessages.get(thread.id);
      const newLocalMessages = oldMessages
        ? [...oldMessages, { id: "id", text: response }]
        : [{ id: "id", text: response }];
      const newLocalMessagesCache = new Map(localMessages);
      newLocalMessagesCache.set(thread.id, newLocalMessages);
      setLocalMessages(newLocalMessagesCache);
      setResponse("");
      setError(null);
    } catch (err) {
      setError(
        "Error sending message. This message is sent outside of allowed window."
      );
      console.error(err);
    }
  };

  useEffect(() => {
    if (thread?.id) {
      // setLocalMessages([]);
    }
  }, [thread]);

  return (
    <div className="flex w-full flex-col">
      <div className="mt-2 flex justify-end">
        <input
          type="text"
          className="mr-2 w-full rounded py-3 px-4 disabled:bg-gray-300 sm:w-1/2"
          name="response"
          value={response}
          placeholder="Say something..."
          id="response"
          onChange={(event) => onChangeHandler(event)}
        />
        <button
          className="rounded border border-blue-500 bg-transparent py-2 px-2 text-center font-semibold text-blue-700 hover:border-transparent hover:bg-blue-500 hover:text-white"
          onClick={() => sendMessage()}
        >
          Send
        </button>
      </div>
      {error !== null && (
        <div className="mx-1 w-full bg-red-600 py-3 text-center text-white">
          {error}
        </div>
      )}
      <div className="flex w-full flex-col">
        {localMessages.get(thread.id)?.map((message) => {
          return (
            <div key={message.id} className="mt-2 flex justify-end">
              <div className="max-w-2/3 rounded-lg bg-blue-300 py-3 px-4">
                <div className="whitespace-pre-line">{message.text}</div>
                <p className="text-right text-xs text-white">just now</p>
              </div>
            </div>
          );
        })}
        {thread.messages
          .slice(0)
          .reverse()
          .map((message) => {
            const showMessageLeft = message.from === username;
            return (
              <div
                key={message.id}
                className={`flex ${
                  showMessageLeft ? "ml-2" : "justify-end"
                } mt-2`}
              >
                <div
                  className={`max-w-2/3 rounded-lg py-3 px-4 ${
                    showMessageLeft ? "bg-emerald-300" : "bg-blue-300"
                  }`}
                >
                  {message.text ? (
                    <div className="whitespace-pre-line">{message.text}</div>
                  ) : (
                    <div className="whitespace-pre-line">
                      {message.storyUrl || message.storyUrl === "" ? (
                        <StoryMentionViewer storyUrl={message.storyUrl} />
                      ) : (
                        <p className="font-bold italic">
                          This message type is not supported. View it on the
                          Instagram app.
                        </p>
                      )}
                    </div>
                  )}
                  <p className="text-right text-xs text-white">
                    {timeDifferenceForDate(Number(message.createdAt))}
                  </p>
                </div>
              </div>
            );
          })}
      </div>
    </div>
  );
}

function StoryMentionViewer({ storyUrl }) {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [storyVideoUrl, setStoryVideoUrl] = useState<string>();
  const [storyError, setStoryError] = useState<boolean>(false);

  return (
    <>
      <button
        onClick={() => {
          setIsModalOpen(true);
        }}
        className="flex items-center rounded-lg px-2 font-bold text-kale-green-500 hover:bg-kale-mint-500 disabled:bg-kale-green-300"
      >
        View story mention
      </button>
      <Modal isModalOpen={isModalOpen} onClose={() => setIsModalOpen(false)}>
        <div className="flex w-full justify-center">
          {storyUrl && !storyVideoUrl && (
            <img
              className="h-[473px]  w-[266px] rounded-2xl"
              src={storyUrl}
              onError={() => {
                setStoryVideoUrl(storyUrl);
              }}
            />
          )}
          {storyVideoUrl && !storyError && (
            <video
              muted={true}
              autoPlay={true}
              loop={true}
              className="h-[473px]  w-[266px] rounded-2xl"
              src={storyVideoUrl}
              onError={() => {
                setStoryError(true);
              }}
            />
          )}
          {(storyError || storyUrl === "") && (
            <div className="flex h-[473px] w-[266px] items-center justify-center rounded-2xl bg-kale-mint-500 text-center">
              <p>The story is no longer available.</p>
            </div>
          )}
        </div>
      </Modal>
    </>
  );
}
