import { useContext } from "react";
import ContentBudgetSettings from "./ContentBudgetSettings";
import { UserContext } from "../../../providers/UserProvider";

export function FundsPage() {
  const { brandRecord, updateBrandRecord } = useContext(UserContext);

  return (
    <div className="flex w-full items-center">
      <ContentBudgetSettings
        brand={brandRecord}
        updateBrandRecord={updateBrandRecord}
      />
    </div>
  );
}
