import {
  Challenge,
  Modal,
  PostRequirements,
  VideoRequirements,
} from '@kalecard/common';
import {
  ArrowTopRightOnSquareIcon,
  ChatBubbleLeftIcon,
  CheckCircleIcon,
  ClipboardIcon,
} from '@heroicons/react/24/outline';
import { useContext, useState } from 'react';
import ReviewChallengeForm from '../forms/ReviewChallengeForm';
import { UserContext } from '../../providers/UserProvider';
import { BadgesContext } from '../../providers/BadgesProvider';
import { classNames } from '../../utils/style';
import { Dialog } from '../catalyst/dialog';

export default function PendingChallengeRow({
  challenge,
}: {
  challenge: Challenge;
}) {
  const [isApproved, setIsApproved] = useState(null);
  const [isReviewModalOpen, setIsReviewModalOpen] = useState(false);
  const [isChallengeReqModalOpen, setIsChallengeReqModalOpen] = useState(false);
  const { brandId } = useContext(UserContext);
  const { badges, setBadges } = useContext(BadgesContext);

  const handleApproveClick = () => {
    setIsApproved(true);
    setIsReviewModalOpen(true);
  };

  const handleRequestEditsClick = () => {
    setIsApproved(false);
    setIsReviewModalOpen(true);
  };

  const onReviewChallenge = () => {
    setIsReviewModalOpen(false);
    setBadges({
      ...badges,
      challengesBadges: {
        ...badges.challengesBadges,
        pendingReviewFromBrand: badges.challengesBadges.pendingReviewFromBrand
          ? badges.challengesBadges.pendingReviewFromBrand - 1
          : 0,
      },
    });
  };

  return (
    <>
      {challenge && (
        <>
          <tr>
            <td className="w-2/5 text-wrap py-4 pr-6 text-sm text-gray-500">
              <div className="text-gray-900">{challenge.description}</div>
              <div className="text-gray-500">{challenge.tagRequirements}</div>
              {challenge.exampleUrl && (
                <a
                  className="flex items-center space-x-1 pt-2 text-kale-green-500"
                  href={challenge.exampleUrl}
                  target="_blank"
                  rel="noreferrer"
                >
                  <p>See Example</p>
                  <ArrowTopRightOnSquareIcon className="h-4 w-4" />
                </a>
              )}
            </td>
            <td className="px-2 text-center text-sm text-gray-900">
              {challenge.createdAt &&
                new Date(Number(challenge.createdAt)).toLocaleDateString()}
            </td>
            <td className="px-2">
              <div
                className="flex items-center justify-center space-x-2"
                onClick={() => setIsChallengeReqModalOpen(true)}
              >
                <ClipboardIcon className="h-4 w-4 text-gray-900" />
                <p className="text-sm text-gray-900 underline underline-offset-4 hover:cursor-pointer">
                  View Requirements
                </p>
              </div>
            </td>
            <td className="px-2">
              <div
                className="flex items-center justify-center space-x-2"
                onClick={handleApproveClick}
              >
                <CheckCircleIcon className="h-4 w-4 text-gray-900" />
                <p className="text-sm text-gray-900 underline underline-offset-4 hover:cursor-pointer">
                  Approve
                </p>
              </div>
            </td>
            <td className="px-2 text-center">
              <div
                className="flex items-center justify-center space-x-2"
                onClick={handleRequestEditsClick}
              >
                <ChatBubbleLeftIcon className="h-4 w-4 text-gray-900" />
                <p className="text-sm text-gray-900 underline underline-offset-4 hover:cursor-pointer">
                  Request Edits
                </p>
              </div>
            </td>
          </tr>
          <Modal
            isModalOpen={isReviewModalOpen}
            onClose={() => setIsReviewModalOpen(false)}
            className={classNames(isApproved ? 'w-fit' : '')}
          >
            <ReviewChallengeForm
              brandId={brandId}
              challenge={challenge}
              isApproved={isApproved}
              onSubmit={onReviewChallenge}
            />
          </Modal>
          <Dialog
            open={isChallengeReqModalOpen}
            onClose={() => setIsChallengeReqModalOpen(false)}
            size="md"
          >
            <div className="flex flex-row justify-center">
              <div className="space-y-8">
                <PostRequirements
                  tagRequirements={challenge?.tagRequirements as string}
                  showCommercialSounds={
                    challenge?.isCommercialSoundsOnly as boolean
                  }
                  hideSoundExampleLink={true}
                />
                <VideoRequirements challenge={challenge} />
              </div>
            </div>
          </Dialog>
        </>
      )}
    </>
  );
}
