import { Description, Field, Label } from "../../catalyst/fieldset";
import KaleBrandsHelmet from "../../utils/KaleBrandsHelmet";
import InstagramSettings from "./InstagramSettings";

export default function InstagramSettingsPage() {
  return (
    <>
      <KaleBrandsHelmet pageName="Instagram Settings" />
      <div className="mx-auto max-w-7xl px-4 text-sm text-gray-700 sm:px-6 md:px-8">
        <div className="space-y-8 divide-y divide-gray-200 sm:space-y-5">
          <div className="space-y-6 sm:space-y-5">
            <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:pt-5">
              <Field>
                <Label>Instagram Settings</Label>
                <Description>
                  Details about your Instagram connected accounts.
                </Description>
              </Field>

              <div className="mt-1 flex w-full items-center sm:col-span-2 sm:mt-0">
                <InstagramSettings />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
