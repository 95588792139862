import { createContext, useReducer } from "react";
import { ContentReducer, ContentState } from "../reducers/ContentReducer";

export const ContentContext = createContext<{
  state: ContentState;
  dispatch: React.Dispatch<{}>;
}>(null);

export default function ContentProvider({
  contentState,
  children,
}: {
  contentState?: ContentState;
  children?: any;
}) {
  const [state, dispatch] = useReducer(
    ContentReducer,
    getInitialState(contentState)
  );

  return (
    <ContentContext.Provider value={{ state: state, dispatch }}>
      {children}
    </ContentContext.Provider>
  );
}

function getInitialState(contentState: ContentState): ContentState {
  return {
    content: contentState.content || [],
    hasNextPage: contentState.hasNextPage,
    after: contentState.after,
    shouldUpdateContent: contentState.shouldUpdateContent,
    sortingType: contentState.sortingType,
    challengeId: contentState.challengeId,
    selectableChallenges: contentState.selectableChallenges || [],
  };
}
