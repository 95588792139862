import KaleBrandsHelmet from "../components/utils/KaleBrandsHelmet";
import { ChevronDoubleRightIcon } from "@heroicons/react/24/solid";
import { useQuery } from "@apollo/client";
import { Loading, Spinner } from "@kalecard/common";
import { FACEBOOK_BUSINESSES } from "../graphql/queries";

export default function FacebookPage() {
  const { data, loading } = useQuery(FACEBOOK_BUSINESSES);
  return (
    <>
      <KaleBrandsHelmet pageName="Facebook Businesses" />
      <div className="mx-auto max-w-7xl px-4 text-sm text-gray-700 sm:px-6 md:px-8">
        <div className="space-y-8 divide-y divide-gray-200 sm:space-y-5">
          <div className="space-y-6 sm:space-y-5">
            <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:pt-5">
              <label
                htmlFor="username"
                className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
              >
                Facebook Businesses
                <p className="text-xs text-gray-500">
                  Details about the Facebook Businesses you manage.
                </p>
              </label>

              <div className="mt-1 flex w-full items-center sm:col-span-2 sm:mt-0">
                <div className={"flex w-full flex-col space-y-2"}>
                  {loading && <Spinner size={"h-5 w-5"} />}
                  {data?.facebookBusinesses?.map((business) => {
                    return (
                      <a
                        target={"_blank"}
                        href={business.link}
                        className="flex w-full items-center justify-between rounded-md border border-gray-200 p-2 transition-colors duration-200 ease-in-out hover:bg-gray-100"
                        key={business.id}
                        rel="noreferrer"
                      >
                        <div className={"flex space-x-2"}>
                          {business.profilePictureUri && (
                            <img
                              className={"h-6 w-6"}
                              src={business.profilePictureUri}
                              alt={"profile picture"}
                            />
                          )}
                          <h2 className={"font-bold"}>{business.name}</h2>
                        </div>
                        <ChevronDoubleRightIcon className={"h-4 w-4"} />
                      </a>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
