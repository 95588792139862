import {
  Challenge,
  VideoRequirements,
  PostRequirements,
  Modal,
} from "@kalecard/common";
import { useContext, useState } from "react";
import { UserContext } from "../../providers/UserProvider";
import {
  EllipsisHorizontalIcon,
  HandRaisedIcon,
  VideoCameraIcon,
} from "@heroicons/react/24/outline";
import ChallengeFeedbackForm from "../forms/ChallengeFeedbackForm";
import {
  formatMetric,
  renderActivationStatus,
  renderChallengeState
} from "./ChallengeData";
import {
  Dropdown,
  DropdownButton,
  DropdownItem,
  DropdownMenu,
} from "../catalyst/dropdown";
import { Dialog } from "../catalyst/dialog";
import { Tooltip } from "../Tooltip";
import { Button } from "../catalyst/button";

export default function ChallengeRow({ challenge }: { challenge: Challenge }) {
  const { brandRecord } = useContext(UserContext);
  const [isFeedbackModalOpen, setIsFeedbackModalOpen] = useState(false);
  const [isChallengeReqModalOpen, setIsChallengeReqModalOpen] = useState(false);
  const [isActivePeriodRequestModalOpen, setIsActivePeriodRequestModalOpen] =
    useState(false);
  const hasActivePeriodRequest = challenge.feedback?.some(
    feedbackItem => feedbackItem.status !== 'ADDRESSED' && (feedbackItem.feedback.startsWith("Activation Request") || feedbackItem.feedback.startsWith("Deactivation Request"))
  );

  const renderModals = () => {
    return (
      <>
        <Modal
          isModalOpen={isFeedbackModalOpen}
          onClose={() => setIsFeedbackModalOpen(false)}
        >
          <div className="flex w-full">
            <ChallengeFeedbackForm
              brand={brandRecord}
              challenge={challenge}
              requestingActivationOrDeactivation={false}
              buttonCTA="Feedback"
            />
          </div>
        </Modal>
        <Dialog
          open={isChallengeReqModalOpen}
          onClose={() => setIsChallengeReqModalOpen(false)}
          size="md"
        >
          <div className="flex flex-row justify-center">
            <div className="space-y-8">
              <PostRequirements
                tagRequirements={challenge?.tagRequirements as string}
                showCommercialSounds={
                  challenge?.isCommercialSoundsOnly as boolean
                }
                hideSoundExampleLink={true}
              />
              <VideoRequirements challenge={challenge} />
            </div>
          </div>
        </Dialog>
        <Modal
          isModalOpen={isActivePeriodRequestModalOpen}
          onClose={() => setIsActivePeriodRequestModalOpen(false)}
          className="w-fit"
        >
          <ChallengeFeedbackForm
            brand={brandRecord}
            challenge={challenge}
            requestingActivationOrDeactivation={true}
            buttonCTA={`${
              challenge.state === "ACTIVE" ? "Deactivation" : "Activation"
            } Request`}
            closeModal={() => setIsActivePeriodRequestModalOpen(false)}
          />
        </Modal>
      </>
    );
  };

  return (
    <>
      {challenge && (
        <>
          <tr>
            <td className="w-2/5 text-wrap py-4 pr-6 text-sm text-gray-500">
              {challenge.title ? (
                <div className="font-medium text-gray-900">
                  {challenge.title}
                </div>
              ) : null}
              <div className="text-gray-900">{challenge.description}</div>
              <div className="text-gray-500">{challenge.tagRequirements}</div>
              <div className="flex space-x-4 pt-2">
                {challenge.state !== "PENDING_REVIEW" &&
                  challenge.totalPosts > 0 &&
                  challenge.totalImpressions > 0 && (
                    <Button
                      href={`/challenges/${challenge.id}`}
                      rel="noreferrer"
                      outline
                    >
                      View Content
                    </Button>
                  )}

                {challenge.totalImpressions > 0 && (
                  <Button href={`/challenges/${challenge.id}/stats`} outline>
                    View Stats
                  </Button>
                )}

                {challenge.exampleUrl && (
                  <Button
                    href={challenge.exampleUrl}
                    target="_blank"
                    rel="noreferrer"
                    outline
                  >
                    <p>See Example</p>
                  </Button>
                )}
                <Button
                  className="hover:cursor-pointer"
                  onClick={() => setIsChallengeReqModalOpen(true)}
                  outline
                >
                  View Requirements
                </Button>
              </div>
            </td>
            <td className="px-2 text-center text-sm text-gray-900">
              {(challenge.startDate || challenge.firstActivationDate) &&
                new Date(
                  challenge.startDate
                    ? Number(challenge.startDate)
                    : Number(challenge.firstActivationDate)
                ).toLocaleDateString()}
            </td>
            <td className="px-2 text-center text-sm text-gray-900">
              {challenge.endDate &&
                new Date(Number(challenge.endDate)).toLocaleDateString()}
            </td>
            <td className="space-y-2">
              <Tooltip message="Total posts">
                <div className="mx-auto flex w-fit items-center justify-center space-x-1 rounded-full bg-kale-mint-400 px-3 py-1.5 text-xs">
                  <VideoCameraIcon className="h-4 w-4" />
                  <div>
                    {challenge.totalImpressions > 0
                      ? formatMetric(challenge.totalPosts)
                      : 0}
                  </div>
                </div>
              </Tooltip>

              <Tooltip message="All-time claims for this challenge">
                <div className="mx-auto flex w-fit items-center justify-center space-x-1 rounded-full bg-kale-mint-400 px-3 py-1.5 text-xs">
                  <HandRaisedIcon className="h-4 w-4" />
                  <div>
                    {challenge.totalClaims > 0
                      ? formatMetric(challenge.totalClaims)
                      : 0}
                  </div>
                </div>
              </Tooltip>
            </td>
            <td>
              <div className="flex flex-col items-center space-x-1 text-xs">
                {renderChallengeState(challenge.state)}
                {renderActivationStatus(challenge)}
              </div>
            </td>
            <td className="text-center">
              <Dropdown>
                <DropdownButton
                  plain
                  aria-label="More options"
                  className="mx-auto flex hover:cursor-pointer"
                >
                  <EllipsisHorizontalIcon className="h-5 w-5 text-black" />
                </DropdownButton>
                <DropdownMenu>
                  {challenge.state !== "REJECTED" && (
                    <DropdownItem
                      className="hover:cursor-pointer"
                      onClick={() => {
                        setIsFeedbackModalOpen(true);
                      }}
                    >
                      Give Feedback
                    </DropdownItem>
                  )}
                  {(challenge.state === "ACTIVE" ||
                    challenge.state === "INACTIVE") && (
                    <DropdownItem
                      className={`${hasActivePeriodRequest ? "hover:cursor-not-allowed data-[focus]:bg-gray-300" : "hover:cursor-pointer" }`}
                      onClick={() => {
                        setIsActivePeriodRequestModalOpen(true);
                      }}
                      disabled={hasActivePeriodRequest}
                    >
                      Request{" "}
                      {challenge.state === "ACTIVE"
                        ? "Deactivation"
                        : "Activation"}
                    </DropdownItem>
                  )}
                </DropdownMenu>
              </Dropdown>
            </td>
            {renderModals()}
          </tr>
        </>
      )}
    </>
  );
}
