import { useQuery } from "@apollo/client";
import { Edge, Spinner, Thread } from "@kalecard/common";
import { useContext, useEffect, useState } from "react";
import { useHistory, useParams } from "react-router";
import Messages from "../components/settings/instagram/Messsages";
import SelectionComponent, {
  SelectionComponentItem,
} from "../components/settings/instagram/SelectionComponent";
import { BRAND_THREADS } from "../graphql/queries";
import { UserContext } from "../providers/UserProvider";

export default function InstagramMessagesPage() {
  const params = useParams();
  const facebookAccountId = params["id"];
  const { brandId } = useContext(UserContext);
  const [edges, setEdges] = useState<Edge<Thread>[]>([]);
  const [after, setAfter] = useState(null);
  const [hasNextPage, setHasNextPage] = useState(false);
  const [thread, setThread] = useState<Thread | null>(null);
  const history = useHistory();

  const setData = (data: any) => {
    console.log(data);
    setHasNextPage(data.brandThreads.pageInfo.hasNextPage);
    setAfter(
      data.brandThreads.edges[data.brandThreads.edges.length - 1]?.cursor
    );
    const newEdges = [...edges, ...data.brandThreads.edges];
    setEdges(newEdges);
    setThread(newEdges.find((edge) => edge.node.id === params["mid"])?.node);
  };

  function formatAsSelectionItem(
    edges: Edge<Thread>[]
  ): SelectionComponentItem[] {
    return edges.map((edge) => {
      return {
        id: edge.node.id,
        name: edge.node.participants[1].username,
      };
    });
  }

  function handleThreadSelected(item: SelectionComponentItem) {
    history.push(`/instagram/${facebookAccountId}/messages/${item.id}`);
    setThread(edges.find((edge) => edge.node.id === item.id)?.node);
  }

  const { loading } = useQuery(BRAND_THREADS, {
    variables: {
      first: 20,
      brandId: brandId,
      facebookAccountId: facebookAccountId,
    },
    onCompleted: setData,
  });

  // useEffect(() => {
  //   setThread(edges.find((edge) => edge.node.id === params["mid"])?.node);
  // }, [edges])

  return (
    <>
      <div className="mx-auto max-w-7xl px-4 pb-2 sm:px-6 md:px-8">
        <h1 className="text-2xl font-semibold text-gray-900">
          Instagram Messages
        </h1>
      </div>
      <div className="mx-auto max-w-7xl px-4 sm:px-6 md:px-8">
        {loading && <Spinner size={"h-5 w-5"} />}
        {!loading && (
          <div className="">
            <SelectionComponent
              list={formatAsSelectionItem(edges)}
              selectedId={params["mid"]}
              onSelect={handleThreadSelected}
              defaultTitle="Choose a thread"
            />
            {thread && <Messages thread={thread} />}
          </div>
        )}
      </div>
    </>
  );
}
