import {
  InstagramLogo,
  Post,
  TiktokLogo,
  numberWithCommas,
} from "@kalecard/common";
import {
  ChatBubbleOvalLeftIcon,
  EyeIcon,
  HeartIcon,
} from "@heroicons/react/24/outline";
import { classNames } from "../../utils/style";

function PostCaptionStats({ post, hideIG }: { post: Post; hideIG?: boolean }) {
  let likeCount = 0;
  let commentCount = 0;
  let impressionCount = 0;

  const allPosts = post.siblings ? [post, ...post.siblings] : [post];
  allPosts.forEach((relatedPost) => {
    if (hideIG && relatedPost.externalPlatform === "INSTAGRAM") {
      return;
    }
    if (relatedPost.likeCount && relatedPost.likeCount !== "-1") {
      likeCount += Number(relatedPost.likeCount);
    }
    if (relatedPost.commentCount && relatedPost.commentCount !== "-1") {
      commentCount += Number(relatedPost.commentCount);
    }
    if (relatedPost.impressions && relatedPost.impressions !== "-1") {
      impressionCount += Number(relatedPost.impressions);
    }
  });

  const captions = allPosts.map((relatedPost, index) => {
    if (hideIG && relatedPost.externalPlatform === "INSTAGRAM") {
      return null;
    }
    return (
      <div
        key={`${relatedPost.id}-${index}`}
        className={classNames(index != 0 ? "pt-1" : "")}
      >
        <Caption post={relatedPost} />
      </div>
    );
  });

  return (
    <div className="flex h-full flex-col justify-between space-y-1 border-b">
      <div className="space-y-1 divide-y">{captions}</div>
      <div className="flex w-full justify-between px-1 py-1 text-sm">
        {likeCount !== null && likeCount > 0 && (
          <div className="flex items-center space-x-1">
            <HeartIcon className="h-4 w-4" />
            <p>{numberWithCommas(likeCount)}</p>
          </div>
        )}
        {commentCount !== null && commentCount > 0 && (
          <div className="flex items-center space-x-1">
            <ChatBubbleOvalLeftIcon className="h-4 w-4" />
            <p>{numberWithCommas(commentCount)}</p>
          </div>
        )}
        {impressionCount !== null && impressionCount > 0 && (
          <div className="flex items-center space-x-1">
            <EyeIcon className="h-4 w-4" />
            <p>{numberWithCommas(impressionCount)}</p>
          </div>
        )}
      </div>
    </div>
  );
}

function Caption({ post }: { post: Post }) {
  const username = post.externalUser?.username;

  return (
    <div className="w-[260px] px-1 pt-1 text-left text-xs text-kale-green-1000">
      {username && (
        <div className="pb-1">
          <a
            className="py-1 text-sm font-semibold text-black hover:cursor-pointer hover:text-kale-green-300"
            href={
              post.externalPlatform === "TIKTOK"
                ? "https://tiktok.com/@" + username
                : "https://instagram.com/" + username
            }
            target="_blank"
            rel="noreferrer"
          >
            @<span>{username}</span>
          </a>
        </div>
      )}
      <a href={post.externalPermalink} target="_blank">
        <span className="flex items-start space-x-1 hover:text-kale-green-300">
          {post.externalPlatform === "INSTAGRAM" ? (
            <span>
              <InstagramLogo />
            </span>
          ) : (
            <span>
              <TiktokLogo />
            </span>
          )}
          <span className="line-clamp-2">{post.caption}</span>
        </span>
      </a>
    </div>
  );
}

export default PostCaptionStats;
