import { useMutation } from "@apollo/client";
import { Spinner } from "@kalecard/common";
import { useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { REQUEST_WHITELISTING_ACCESS } from "../../graphql/mutations";

interface WhitelistAccessRequestFormInputs {}

interface WhitelistAccessRequestFormProps {
  brandId: string;
}

export default function WhitelistAccessRequestForm(
  props: WhitelistAccessRequestFormProps
) {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const [successMessage, setSuccessMessage] = useState("");
  const { reset, register, handleSubmit } =
    useForm<WhitelistAccessRequestFormInputs>();
  const [requestWhitelistingAccess] = useMutation(REQUEST_WHITELISTING_ACCESS);

  const submitWhitelistAccessRequestForm = (
    data: WhitelistAccessRequestFormInputs
  ) => {
    submitForm(data);
  };

  const submitForm: SubmitHandler<WhitelistAccessRequestFormInputs> = async (
    data
  ) => {
    setIsLoading(true);
    console.log(data);
    const variables = {
      brandId: props.brandId,
    };
    try {
      const result = await requestWhitelistingAccess({ variables });
      console.log(result);
      if (result.data.requestWhitelistingAccess == true) {
        setSuccessMessage(
          "Your request was sent successfully to Kale! A representative will contact you soon."
        );
        setError(null);
        reset();
      } else {
        // Set an error
        console.error("Error requesting whitelisting access");
        setError(
          "Something went wrong, try refreshing this page or try again later."
        );
      }
    } catch (err) {
      console.error(err);
      setError("Something went wrong, please try again later.");
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <form className="px-5 md:px-0">
        <div>
          <p className="text-s text-black-400 text-center">
            Whitelisting is the process of converting your creator's content
            into an advertisement. The ad will run from the creator's handle.
          </p>
        </div>
        <div className="flex flex-col justify-center pt-5">
          <button
            type="button"
            onClick={handleSubmit(submitWhitelistAccessRequestForm)}
            disabled={isLoading}
            className="max-w-15 place-self-center rounded-md border border-transparent bg-kale-mint-500 py-2 px-4 text-sm font-medium text-gray-900 shadow-sm hover:bg-green-600 focus:outline-none focus:ring-2 focus:ring-kale-green-500 focus:ring-offset-2"
          >
            Request Whitelisting Access
          </button>
          <div>
            <p className="text-black-400 pt-2 text-center text-xs">
              {successMessage}
            </p>
          </div>
        </div>
        {isLoading && (
          <div className="flex flex-wrap content-center justify-end">
            <Spinner size="h-6 w-6" />
          </div>
        )}
      </form>
    </>
  );
}
