import { CSVLink } from "react-csv";
import { BlockedUser } from "../../__generated__/graphql";

export default function CSVBlockedUsers({
  blockedUsers,
  csvRef,
}: {
  blockedUsers: BlockedUser[];
  csvRef: React.MutableRefObject<any>;
}) {
  const headers = [
    { label: "Username", key: "username" },
    { label: "External Platform", key: "externalPlatform" },
    { label: "External URL", key: "externalUrl" },
  ];

  return (
    <>
      <CSVLink
        data={blockedUsers}
        headers={headers}
        target="_blank"
        filename="blocked_users.csv"
        className="hidden"
        ref={csvRef}
      ></CSVLink>
    </>
  );
}
