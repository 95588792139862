import { Spinner } from "@kalecard/common";
import { BRAND_REPORT, BrandReportInterface } from "../graphql/queries";
import { useQuery } from "@apollo/client";

export default function BrandReportDashboard({
  brandId,
  challengeId,
}: {
  brandId: string;
  challengeId?: string;
}) {
  const { data, loading } = useQuery<BrandReportInterface>(BRAND_REPORT, {
    variables: {
      brandId: brandId,
      challengeId: challengeId,
    },
  });

  return loading ? (
    <div className="flex justify-center pt-2">
      <Spinner size={"h-8 w-8"} />
    </div>
  ) : (
    <iframe
      src={data?.brandReport?.dashboardUrl}
      style={{ width: "100%", height: "100vh" }}
    />
  );
}
