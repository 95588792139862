import { useContext, useState } from "react";
import { UserContext } from "../../../providers/UserProvider";
import KaleBrandsHelmet from "../../utils/KaleBrandsHelmet";
import { BrandUser, Modal, Spinner } from "@kalecard/common";
import { useMutation, useQuery } from "@apollo/client";
import { BRAND_ADMINS } from "../../../graphql/queries";
import BrandAdminsTable from "./BrandAdminsTable";
import { UPDATE_BRAND_ADMINS } from "../../../graphql/mutations";
import { Description, Field, Label } from "../../catalyst/fieldset";

interface BrandAdminsInterface {
  brandAdmins: BrandUser[];
}

export default function TeamSettingsPage() {
  const { userRecord, brandRecord } = useContext(UserContext);

  const [brandAdmins, setBrandAdmins] = useState<BrandUser[]>([]);
  const [isAddAdminModalOpen, setIsAddAdminModalOpen] = useState(false);
  const [newAdminEmail, setNewAdminEmail] = useState(null);
  const [updateBrandAdmins] = useMutation(UPDATE_BRAND_ADMINS);
  const [updateAdminsLoading, setUpdateAdminsLoading] = useState(false);

  const { loading } = useQuery<BrandAdminsInterface>(BRAND_ADMINS, {
    variables: {
      brandId: brandRecord?.id,
    },
    fetchPolicy: "network-only",
    skip: !brandRecord,
    onCompleted: (data) => {
      setBrandAdmins(data.brandAdmins);
    },
  });

  const onAddAdmin = async () => {
    setIsAddAdminModalOpen(false);
    const adminEmails = new Set(
      brandAdmins.map((brandAdmin) => brandAdmin.email)
    );
    if (newAdminEmail) {
      adminEmails.add(newAdminEmail);
    }
    const updatedAdmins = new Set(adminEmails);

    setUpdateAdminsLoading(true);
    await updateBrandAdmins({
      variables: {
        brandId: brandRecord?.id,
        brandAdminEmails: Array.from(updatedAdmins),
      },
      refetchQueries: [BRAND_ADMINS],
    });
    setUpdateAdminsLoading(false);
  };

  const onRemoveAdmin = async (admin: BrandUser) => {
    const adminEmails = new Set(
      brandAdmins.map((brandAdmin) => brandAdmin.email)
    );
    adminEmails.delete(admin.email);
    const updatedAdmins = new Set(adminEmails);

    setUpdateAdminsLoading(true);
    await updateBrandAdmins({
      variables: {
        brandId: brandRecord?.id,
        brandAdminEmails: Array.from(updatedAdmins),
      },
      refetchQueries: [BRAND_ADMINS],
    });
    setUpdateAdminsLoading(false);
  };

  const isBrandPOC =
    userRecord.administeredBrands?.find(
      (administeredBrand) => administeredBrand.brand?.id === brandRecord?.id
    )?.role === "BRAND_OWNER";

  return (
    <>
      <KaleBrandsHelmet pageName="Team Settings" />

      <div className="mx-auto max-w-7xl px-4 text-sm text-gray-700 sm:px-6 md:px-8">
        <div className="space-y-8 divide-gray-200 sm:space-y-5">
          <div className="space-y-6 sm:space-y-5">
            <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:pt-5">
              <Field>
                <Label>Brand Admins</Label>
                <Description>View all admins on your team.</Description>
              </Field>
              {(loading || updateAdminsLoading) && (
                <div className="mr-2">
                  <Spinner size="h-6 w-6" />
                </div>
              )}
              {!loading && !updateAdminsLoading && (
                <div className="col-span-1">
                  <BrandAdminsTable
                    admins={brandAdmins}
                    isBrandPOC={isBrandPOC}
                    removeAdmin={onRemoveAdmin}
                  />
                </div>
              )}
              <div className="col-start-2 flex justify-end">
                <button
                  className="inline-flex justify-center rounded-md border border-transparent bg-indigo-600 px-2 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 disabled:opacity-60"
                  onClick={() => setIsAddAdminModalOpen(true)}
                >
                  Add Team Member
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal
        className="w-1/3"
        isModalOpen={isAddAdminModalOpen}
        onClose={() => setIsAddAdminModalOpen(false)}
      >
        <label
          htmlFor="newAdminEmail"
          className="text-sm font-medium text-gray-500"
        >
          Add a new brand admin.
        </label>
        <div className="mt-1 flex flex-row justify-between">
          <input
            onChange={(e) => setNewAdminEmail(e.target.value)}
            type="email"
            name="newAdminEmail"
            id="newAdminEmail"
            className="block w-1/2 rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
            placeholder="you@example.com"
          />
          <div className="flex justify-end">
            <button
              className="inline-flex justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 disabled:opacity-60"
              onClick={onAddAdmin}
            >
              Add
            </button>
          </div>
        </div>
        <p className="pt-4 text-center text-xs text-gray-500">
          After being added, the new team member will have access to the
          dashboard upon signing up at https://brands.kalecard.com.
        </p>
      </Modal>
    </>
  );
}
