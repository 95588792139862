import { useContext } from "react";

import BrandReview from "../components/bonsai/brand-review/BrandReview";
import KaleBrandsHelmet from "../components/utils/KaleBrandsHelmet";
import { UserContext } from "../providers/UserProvider";
import { BRAND_REVIEW_ALLOW_LIST_BRAND_IDS } from "../config/betaAllowLists";

const BRAND_ID_TO_QUEUE_NAME = {
  "528": "bubble_brand_review",
  "620": "starry_brand_review",
  "654": "cinnabon_video_upload_review",
  "246": "kale_test_video_upload_review",
};

export default function VideoReviewPage() {
  const { brandId } = useContext(UserContext);
  const queueName = BRAND_ID_TO_QUEUE_NAME[brandId];

  if (!BRAND_REVIEW_ALLOW_LIST_BRAND_IDS.has(brandId) || !queueName) {
    return null;
  }

  return (
    <>
      <KaleBrandsHelmet pageName="Content" />
      <div className="mx-6">
        <h1 className="border-b pb-4 text-2xl font-semibold text-gray-900">
          Video Review
        </h1>
        <BrandReview queueName={queueName} />
      </div>
    </>
  );
}
