import { Helmet } from "react-helmet";
import { useForm } from "react-hook-form";

export type LoginInputs = {
  email: string;
  password: string;
};

interface LoginFormInterface {
  submitForm: (data: LoginInputs) => Promise<void>;
}

export default function LoginForm({ submitForm }: LoginFormInterface) {
  const { register, handleSubmit } = useForm<LoginInputs>();

  const submitLoginForm = (data: LoginInputs) => {
    submitForm(data);
  };

  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Kale | Login</title>
        <meta property="og:title" content="Kale | Login" />
        <meta property="og:description" content="Login to Kale" />
        <meta property="og:type" content="website" />
      </Helmet>
      <div className="flex min-h-full flex-col justify-center sm:px-6 lg:px-8">
        <div className="sm:mx-auto sm:w-full sm:max-w-md">
          <h2 className="mt-2 text-center text-2xl font-bold text-kale-green-500">
            Sign in to Kale
          </h2>
        </div>

        <div className="mt-8 pb-1 sm:mx-auto sm:w-full sm:max-w-md">
          <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
            <form
              className="space-y-6"
              action="#"
              method="POST"
              onSubmit={handleSubmit(submitLoginForm)}
            >
              <div>
                <label
                  htmlFor="email"
                  className="block text-sm font-medium text-gray-700"
                >
                  Email address
                </label>
                <div className="mt-1">
                  <input
                    id="email"
                    name="email"
                    type="email"
                    autoComplete="email"
                    {...register("email", { required: true })}
                    className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                  />
                </div>
              </div>

              <div>
                <label
                  htmlFor="password"
                  className="block text-sm font-medium text-gray-700"
                >
                  Password
                </label>
                <div className="mt-1">
                  <input
                    id="password"
                    name="password"
                    type="password"
                    {...register("password", { required: true })}
                    className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                  />
                </div>
              </div>

              <div>
                <button
                  type="submit"
                  className="flex w-full justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                >
                  Sign in
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}
