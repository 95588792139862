import { useQuery } from "@apollo/client";
import { Connection, FacebookAccount, Spinner } from "@kalecard/common";
import { useContext, useState } from "react";
import { FACEBOOK_ACCOUNTS } from "../../../graphql/queries";
import { UserContext } from "../../../providers/UserProvider";
import FacebookAccounts from "./FacebookAccounts";
import FacebookConnect from "./FacebookConnect";
import { NavLink } from "react-router-dom";

export interface FacebookAccountConnectionData {
  facebookAccounts: Connection<FacebookAccount>;
}

export default function InstagramSettings() {
  const { user, brandId } = useContext(UserContext);
  const [rawFacebookAccounts, setRawFacebookAccounts] = useState([]);
  const { loading, data } = useQuery<FacebookAccountConnectionData>(
    FACEBOOK_ACCOUNTS,
    {
      variables: {
        first: 20,
        after: null,
        brandId,
      },
    }
  );

  if (loading) {
    return <Spinner size="h-5 w-5" />;
  }

  return (
    <div className="flex w-full flex-col space-y-4 divide-y divide-gray-200">
      {user.uid === "Fvx1q2IZBWYb2aUTTRp9I6yrzm02" && (
        <NavLink
          to={"/facebook"}
          className="text-sm underline hover:text-blue-500"
        >
          View Facebook Business Accounts
        </NavLink>
      )}
      <FacebookAccounts
        facebookAccounts={data?.facebookAccounts}
        rawFacebookAccounts={rawFacebookAccounts}
      />
      <div className="pt-2">
        <h3 className="pb-2">
          Connect {data?.facebookAccounts?.edges?.length > 0 && "more"}{" "}
          Instagram accounts through Facebook.
        </h3>
        <FacebookConnect setRawFacebookAccounts={setRawFacebookAccounts} />
      </div>
    </div>
  );
}
