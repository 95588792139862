import { useContext } from "react";
import { sortingTypes } from "./SortingTypes";
import { ContentContext } from "../../providers/ContentProvider";
import AllContent from "./AllContent";

export default function ChallengeContent() {
  const {
    dispatch: contentDispatch,
    state: { sortingType },
  } = useContext(ContentContext);

  return (
    <div className="space-y-2">
      <div className="mx-6 border-b">
        {sortingTypes(contentDispatch, sortingType)}
      </div>
      <AllContent />
    </div>
  );
}
