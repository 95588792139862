//Here we set stripe publishable keys for dev and production environments

export const publishableKey =
  process.env.NODE_ENV === "development"
    ? "pk_test_51Kb9hkJ9EmSs74ghZ5rvbwpUqXYMzPzGrfPj2JKUt2K3LmqaOjFZwOqjw29bTsGlrccP1g65hHsPQDc5thm2Xpop00rEYIWJVl"
    : "pk_live_51Kb9hkJ9EmSs74ghdBE7L3qTSFpv7ZhRk6gUHSQafU4r3n6KPUZlpEGZR5osGf9HDjktL5KmiVx1G37gttTEocnI00OKhJQXmQ";

export const renderCreditCardBrand = (brand) => {
  let brandSource;
  switch (brand) {
    case "visa": {
      brandSource =
        "https://storage.googleapis.com/kale-public/kale-brands/credit%20card%20logos/visa.jpeg";
      break;
    }
    case "mastercard": {
      brandSource =
        "https://storage.googleapis.com/kale-public/kale-brands/credit%20card%20logos/mastercard.png";
      break;
    }
    case "unionpay": {
      brandSource =
        "https://storage.googleapis.com/kale-public/kale-brands/credit%20card%20logos/unionpay.png";
      break;
    }
    case "amex": {
      brandSource =
        " https://storage.googleapis.com/kale-public/kale-brands/credit%20card%20logos/amex.png";
      break;
    }
    case "jcb": {
      brandSource =
        " https://storage.googleapis.com/kale-public/kale-brands/credit%20card%20logos/jcb.png";
      break;
    }
    case "discover": {
      brandSource =
        "https://storage.googleapis.com/kale-public/kale-brands/credit%20card%20logos/discover.png";
      break;
    }
    case "cartes": {
      brandSource =
        "https://storage.googleapis.com/kale-public/kale-brands/credit%20card%20logos/cartes.png";
      break;
    }
    case "diners": {
      brandSource =
        "https://storage.googleapis.com/kale-public/kale-brands/credit%20card%20logos/diners.png";
      break;
    }
    default: {
      brandSource =
        "https://storage.googleapis.com/kale-public/kale-brands/credit%20card%20logos/card.png";
      break;
    }
  }

  return brandSource;
};
