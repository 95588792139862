import { useContext, useState } from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { useHistory } from "react-router";
import { useMutation } from "@apollo/client";
import { WhitelistContent } from "@kalecard/common";
import { WHITELIST_CONTENT } from "../../graphql/queries";
import { CONTENT_WHITELIST_REQUEST } from "../../graphql/mutations";
import { UserContext } from "../../providers/UserProvider";
import MediaComponent from "../MediaComponent";
import PostCaptionStats from "../utils/PostCaptionStats";

type ContentWhitelistRequestFormInputs = {
  budget: number;
};

type ContentWhitelistRequestFormProps = {
  whitelistContent: WhitelistContent;
  closeModal: () => void;
};

const MINIMUM_AD_BUDGET = 500;

const ContentWhitelistRequestFormV2 = ({
  whitelistContent,
  closeModal,
}: ContentWhitelistRequestFormProps) => {
  const { brandId } = useContext(UserContext);
  const [error, setError] = useState(null);
  const { reset, control, handleSubmit } =
    useForm<ContentWhitelistRequestFormInputs>({
      defaultValues: {
        budget: 500,
      },
    });
  const [contentWhitelistRequest] = useMutation(CONTENT_WHITELIST_REQUEST, {
    refetchQueries: [WHITELIST_CONTENT],
  });
  const history = useHistory();

  const post = whitelistContent.post;
  const mediaUrl = post.mediaUrls[0];

  const submitForm: SubmitHandler<ContentWhitelistRequestFormInputs> = async (
    data
  ) => {
    const variables = {
      postId: post.id,
      childId: mediaUrl?.carouselItem?.id,
      brandId,
      budget: data.budget,
      postWhitelistRequestId: whitelistContent.id,
    };
    try {
      const result = await contentWhitelistRequest({ variables });
      if (result.data.contentWhitelistRequest) {
        setError(null);
        reset();
      } else {
        // Set an error
        console.error("Error creating new content whitelist request.");
        setError(
          "Something went wrong, try refreshing this page or try again later."
        );
      }
    } catch (err) {
      console.error(err);
      setError("Something went wrong, please try again later.");
    }
  };

  const submitContentWhitelistRequestForm = (
    data: ContentWhitelistRequestFormInputs
  ) => {
    if (data.budget < MINIMUM_AD_BUDGET) {
      setError(
        `Whitelisting amount must be greater than $${MINIMUM_AD_BUDGET}.`
      );
    } else {
      setError(null);
      submitForm(data);
      closeModal();
      history.push("/whitelisting/processing");
    }
  };

  return (
    <form
      onSubmit={handleSubmit(submitContentWhitelistRequestForm)}
      className="flex flex-col items-center justify-center space-y-2 px-14 py-4"
    >
      <MediaComponent
        url={mediaUrl?.url || ""}
        mediaType={mediaUrl?.mediaType || "VIDEO"}
        showControls={false}
      />
      <PostCaptionStats post={post} hideIG={true} />
      <div className="flex flex-row items-center space-x-10 text-lg">
        <span>Budget:</span>
        <div className="space-x-2">
          <span>$</span>
          <Controller
            control={control}
            rules={{
              required: true,
            }}
            defaultValue={500}
            render={({ field: { onChange, onBlur, value } }) => (
              <input
                className="my-2 h-10 w-40 items-center justify-center rounded-md border border-gray-400 bg-gray-50 px-3 pb-1 text-right text-lg text-gray-900"
                type="number"
                onWheel={(e) => (e.target as HTMLElement).blur()}
                step="1"
                min="1"
                onKeyDown={(e) => {
                  if (e.key === "." || e.key === "-" || e.key === "Enter") {
                    e.preventDefault();
                  }
                }}
                onBlur={onBlur}
                onChange={onChange}
                value={value}
              />
            )}
            name="budget"
          />
        </div>
      </div>
      <input
        type="submit"
        onClick={handleSubmit(submitContentWhitelistRequestForm)}
        value="Submit Request"
        className="max-w-20 cursor-pointer rounded-md bg-kale-mint-500 py-2 px-4 text-sm font-medium text-gray-900 shadow-sm hover:bg-green-300"
      />
      {error && <div className="mt-2 text-sm text-red-500">{error}</div>}
    </form>
  );
};

export default ContentWhitelistRequestFormV2;
