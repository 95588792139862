import { useContext } from "react";
import { UserContext } from "../../providers/UserProvider";
import BrandLandingPageForm from "../forms/BrandLandingPageForm";
import { MAIN_URL } from "@kalecard/common";
import KaleBrandsHelmet from "../utils/KaleBrandsHelmet";
import { Description, Field, Label } from "../catalyst/fieldset";
import { BrandSetupStepButtonType } from "../setup/BrandSetupStepButton";

export default function LandingPageSettingsPage() {
  const { user, brandRecord } = useContext(UserContext);
  const isSimplifiedView = user.uid === "Fvx1q2IZBWYb2aUTTRp9I6yrzm02";
  const landingPage = `${MAIN_URL}/t/${brandRecord?.referralCode}`;

  return (
    <>
      <KaleBrandsHelmet pageName="Landing Page Settings" />

      <div className="mx-auto max-w-7xl px-4 text-sm text-gray-700 sm:px-6 md:px-8">
        <div className="space-y-8 divide-y divide-gray-200 sm:space-y-5">
          <div className="space-y-6 sm:space-y-5">
            <div className="space-y-6 sm:space-y-5">
              {!isSimplifiedView && (
                <div className="flex flex-col sm:items-start sm:pt-5">
                  <Field>
                    <Label>Kale Landing Page</Label>
                    <Description>
                      Customize the way your Kale landing page looks. Choose
                      three videos to showcase your brand.
                    </Description>
                    <div className="text-xs">
                      <a
                        href={landingPage}
                        className="font-bold text-kale-orange-500 hover:opacity-60"
                      >
                        {landingPage}
                      </a>
                    </div>
                  </Field>

                  <div className="mt-1 flex w-full items-center sm:col-span-2 sm:mt-0">
                    <BrandLandingPageForm
                      key={brandRecord?.id}
                      brand={brandRecord}
                      buttonType={BrandSetupStepButtonType.SAVE}
                      onSave={() => {}}
                      showCancelButton={true}
                    />
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
