import { useMutation } from "@apollo/client";
import { UserIcon } from "@heroicons/react/24/solid";
import { FacebookAccount, Spinner } from "@kalecard/common";
import { useContext, useState } from "react";
import { Link } from "react-router-dom";
import { CHOOSE_BRAND_FACEBOOK_ACCOUNTS } from "../../../graphql/mutations";
import { UserContext } from "../../../providers/UserProvider";

export default function FacebookAccountItem({
  facebookAccount,
  isNewAccount = false,
}: {
  facebookAccount: FacebookAccount;
  isNewAccount?: boolean;
}) {
  const { user, brandId } = useContext(UserContext);
  const [chooseFacebookAccounts] = useMutation(CHOOSE_BRAND_FACEBOOK_ACCOUNTS);
  const [showAddAccount, setShowAddAccount] = useState<boolean>(isNewAccount);
  const [isLoading, setIsLoading] = useState(false);
  const [facebookAccountId, setFacebookAccountId] = useState(
    isNewAccount ? null : facebookAccount.id
  );
  const [imageUrl, setImageUrl] = useState(facebookAccount.imageUrl);
  const [enableTaggedMedia, _] = useState(
    user.uid === "Fvx1q2IZBWYb2aUTTRp9I6yrzm02"
  );

  return (
    <li className="flex items-center py-3 pl-3 pr-4 text-sm">
      <div>
        <div className="flex items-center">
          {imageUrl ? (
            <img
              src={facebookAccount.imageUrl}
              className="h-6 w-6 rounded-full"
              onError={() => {
                setImageUrl(null);
              }}
            />
          ) : (
            <>
              <UserIcon className="h-6 w-6 rounded-full" />
            </>
          )}
          <span className="ml-2 truncate">
            <a
              className="flex items-center font-medium text-kale-green-500 hover:text-kale-green-400 disabled:text-kale-green-400"
              target="_blank"
              href={`https://www.instagram.com/${facebookAccount.name}`}
            >
              {facebookAccount.name}
            </a>
          </span>
        </div>
        {facebookAccountId && (
          <div className="mt-2 flex space-x-2 text-xs">
            <Link
              className="flex items-center font-medium text-kale-green-500 hover:text-kale-green-400 disabled:text-kale-green-400"
              to={`/instagram/${facebookAccountId}/media`}
            >
              View media
            </Link>
            <div className={"border border-kale-green-500"} />
            <Link
              className="flex items-center font-medium text-kale-green-500 hover:text-kale-green-400 disabled:text-kale-green-400"
              to={`/instagram/${facebookAccountId}/messages`}
            >
              View messages
            </Link>
          </div>
        )}
      </div>
      {showAddAccount && (
        <div className="ml-4 flex-shrink-0">
          <button
            onClick={async () => {
              setIsLoading(true);
              try {
                const result = await chooseFacebookAccounts({
                  variables: {
                    brandId,
                    facebookIds: [facebookAccount.id],
                  },
                });
                console.log(result);
                const newAccount = result.data.chooseBrandFacebookAccounts.find(
                  (newFacebookAccount: FacebookAccount) =>
                    facebookAccount.id === newFacebookAccount.facebookId
                );
                setFacebookAccountId(newAccount.id);
                setShowAddAccount(false);
              } catch (err) {
                console.error(err);
              } finally {
                setIsLoading(false);
              }
            }}
            disabled={isLoading}
            className="inline-flex justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 disabled:opacity-60"
          >
            {isLoading && (
              <span className="mr-2">
                <Spinner size={"h-4 w-4"} />
              </span>
            )}
            Click here to complete connection
          </button>
        </div>
      )}
    </li>
  );
}
