import { useQuery } from "@apollo/client";
import BrandChallenges from "../components/challenges/BrandChallenges";
import KaleBrandsHelmet from "../components/utils/KaleBrandsHelmet";
import ChallengesProvider from "../providers/ChallengesProvider";
import {
  ChallengesListInterface,
  PENDING_CHALLENGES,
  PendingChallengesInterface,
  SIMPLE_CHALLENGES_LIST,
} from "../graphql/queries";
import { Spinner } from "@kalecard/common";
import { useContext } from "react";
import { UserContext } from "../providers/UserProvider";
import { Route, Switch } from "react-router";
import ChallengeDetailView from "../components/challenges/ChallengeDetailView";
import PendingChallenges from "../components/challenges/PendingChallenges";
import ChallengeSuggestions from "../components/challenges/ChallengeSuggestions";

export default function ChallengesPage() {
  const { brandId } = useContext(UserContext);
  const { data, loading } = useQuery<ChallengesListInterface>(
    SIMPLE_CHALLENGES_LIST,
    {
      variables: {
        brandId: brandId,
        onlyCompleted: false,
      },
      skip: !brandId,
    }
  );

  const { data: pendingChallengesData, loading: pendingChallengesLoading } =
    useQuery<PendingChallengesInterface>(PENDING_CHALLENGES, {
      variables: {
        brandId: brandId,
      },
      skip: !brandId,
    });

  return (
    <>
      <KaleBrandsHelmet pageName="Challenges" />
      {!loading && !pendingChallengesLoading ? (
        <ChallengesProvider
          challengesState={{
            challenges: data.challengesList.edges.map((edge) => edge.node),
            hasNextPage: data.challengesList.pageInfo.hasNextPage,
            after: data?.challengesList.edges.length.toString(),
            shouldUpdateChallenges: false,
            pendingChallenges: pendingChallengesData.pendingChallenges,
          }}
        >
          <Switch>
            <Route path="/challenges/:id">
              <ChallengeDetailView />
            </Route>
            <Route exact path="/challenges">
              <BrandChallenges />
            </Route>
            <Route exact path="/pendingChallenges">
              <PendingChallenges />
            </Route>
            <Route exact path="/challengeSuggestions">
              <ChallengeSuggestions />
            </Route>
          </Switch>
        </ChallengesProvider>
      ) : (
        <div className="flex justify-center pt-10">
          <Spinner size={"h-8 w-8"} />
        </div>
      )}
    </>
  );
}
