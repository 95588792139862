import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useHistory, useLocation } from "react-router";
import { auth } from "../config/firebase";
import SignUpFooter from "./SignUpFooter";

interface ResetPasswordInputs {
  email: string;
}

export default function ResetPasswordPage({ authenticated }) {
  const { register, handleSubmit } = useForm<ResetPasswordInputs>();
  const locationState: any = useLocation().state;
  const history = useHistory();
  const [success, setSuccess] = useState<string>(null);
  const [error, setError] = useState<string>(null);

  const submitLoginForm = (data: ResetPasswordInputs) => {
    console.log(data);
    auth
      .sendPasswordResetEmail(data.email)
      .then(() => {
        // Password reset email sent!
        setSuccess(
          `Password reset email sent to ${data.email}, check your inbox to reset your password. Check your SPAM folder.`
        );
      })
      .catch((error) => {
        var errorCode = error.code;
        var errorMessage = error.message;
        console.error(error);
        setError(errorMessage);
      });
  };

  useEffect(() => {
    if (authenticated) {
      if (locationState && locationState.from) {
        history.push(locationState.from.pathname);
      } else {
        history.push("/");
      }
    }
    return () => {};
  }, [authenticated]);

  return (
    <>
      <div className="flex min-h-full flex-col justify-center sm:px-6 lg:px-8">
        <div className="sm:mx-auto sm:w-full sm:max-w-md">
          <h2 className="mt-2 text-center text-2xl font-bold text-kale-green-500">
            Reset your password
          </h2>
        </div>

        <div className="mt-8 pb-1 sm:mx-auto sm:w-full sm:max-w-md">
          <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
            {success ? (
              <p className="text-lg font-bold text-kale-green-500">{success}</p>
            ) : (
              <form
                className="space-y-6"
                action="#"
                method="POST"
                onSubmit={handleSubmit(submitLoginForm)}
              >
                <div>
                  <label
                    htmlFor="email"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Email address
                  </label>
                  <div className="mt-1">
                    <input
                      id="email"
                      name="email"
                      type="email"
                      autoComplete="email"
                      {...register("email", { required: true })}
                      className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                    />
                  </div>
                </div>

                <div>
                  <button
                    type="submit"
                    className="flex w-full justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                  >
                    Reset password
                  </button>
                </div>
                {error && (
                  <p className="text-sm font-bold text-red-500">{error}</p>
                )}
              </form>
            )}
          </div>
        </div>
      </div>
      {/* <SignUpFooter isLogin={false} onButtonClick={() => {}} /> */}
    </>
  );
}
