import { Button } from "../catalyst/button";

export enum BrandSetupStepButtonType {
  SAVE_AND_CONTINUE,
  SAVE,
}

export default function BrandSetupStepButton({
  buttonType,
  onClick,
  disabled,
}: {
  buttonType: BrandSetupStepButtonType;
  onClick: () => void;
  disabled: boolean;
}) {
  const words = BrandSetupStepButtonType[buttonType]
    .split("_")
    .map((word) => word.toLowerCase());
  const cta = words
    .map((word, index) =>
      index === 0 ? word.charAt(0).toUpperCase() + word.slice(1) : word
    )
    .join(" ");

  return (
    <Button color="indigo" onClick={onClick} disabled={disabled}>
      {cta}
    </Button>
  );
}
