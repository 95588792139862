import { useContext, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useLazyQuery, useMutation } from "@apollo/client";
import { WhitelistContent } from "@kalecard/common";
import {
  WHITELIST_CONTENT,
  POST_SCORES,
  PostScoresData,
} from "../../graphql/queries";
import { CONTENT_WHITELIST_REJECT } from "../../graphql/mutations";
import MediaComponent from "../MediaComponent";
import PostCaptionStats from "../utils/PostCaptionStats";
import { UserContext } from "../../providers/UserProvider";

type ContentWhitelistRejectFormProps = {
  whitelistContent: WhitelistContent;
  closeModal: () => void;
};

const ContentWhitelistRejectForm = ({
  whitelistContent,
  closeModal,
}: ContentWhitelistRejectFormProps) => {
  const { brandId } = useContext(UserContext);
  const [error, setError] = useState(null);
  const [postScores, setPostScores] = useState(null);
  const { reset, register, getValues, setValue, handleSubmit } = useForm({
    defaultValues: {
      postScores: {},
      brandFeedback: "",
    },
  });
  const [fetchScores] = useLazyQuery<PostScoresData>(POST_SCORES, {
    variables: {
      brandId,
      fetchAll: false,
    },
    onCompleted: (data) => {
      const scores = data?.postScores?.filter(
        (score) => score.inverseDescription && score.inverseDescription !== ""
      );
      setPostScores(scores);
      setValue(
        "postScores",
        scores?.reduce((accum, val) => {
          accum[val.id] = true;
          return accum;
        }, {})
      );
    },
  });
  const [contentWhitelistReject] = useMutation(CONTENT_WHITELIST_REJECT, {
    refetchQueries: [WHITELIST_CONTENT],
  });

  const post = whitelistContent.post;
  const mediaUrl = post.mediaUrls[0];

  const submitContentWhitelistRejectForm = async (data) => {
    const scoresHash = getValues("postScores");
    const uncheckedScoreIds = Object.keys(scoresHash).filter(
      (score) => scoresHash[score]
    );
    const variables = {
      postWhitelistRequestId: whitelistContent.id,
      postScoreIds: uncheckedScoreIds,
      brandFeedback: data.brandFeedback,
    };
    try {
      const result = await contentWhitelistReject({ variables });
      if (result.data.contentWhitelistReject) {
        setError(null);
        reset();
        closeModal();
      } else {
        console.error("Error rejecting content whitelist request.");
        setError(
          "Something went wrong, try refreshing this page or try again later."
        );
      }
    } catch (err) {
      console.error(err);
      setError("Something went wrong, please try again later.");
    }
  };

  useEffect(() => {
    fetchScores();
  }, []);

  return (
    <form className="flex flex-col items-center justify-center space-y-2 px-14 py-4">
      <div className="flex flex-row space-x-4">
        <div className="flex flex-col items-center justify-center space-y-2">
          <MediaComponent
            url={mediaUrl?.url || ""}
            mediaType={mediaUrl?.mediaType || "VIDEO"}
            showControls={false}
          />
          <PostCaptionStats post={post} hideIG={true} />
        </div>
        <div className="flex flex-col items-center justify-center text-sm">
          {postScores?.map((postScore) => (
            <div
              key={postScore.id}
              className="mb-2 flex w-full flex-row items-center justify-center space-x-2"
            >
              <div className="w-4">
                <input
                  type="checkbox"
                  {...register(postScore.id, {
                    onChange: (e) => {
                      const oldScores = getValues("postScores");
                      setValue("postScores", {
                        ...oldScores,
                        [postScore.id]: !e.target.checked,
                      });
                    },
                  })}
                  className="rounded-sm"
                />
              </div>
              <span className="flex-1">{postScore.inverseDescription}</span>
            </div>
          ))}
          <div className="mt-2 flex w-full flex-col space-y-1">
            <div>Additional comments: </div>
            <textarea
              rows={3}
              {...register("brandFeedback")}
              className="rounded-md"
            />
          </div>
        </div>
      </div>
      <input
        type="submit"
        onClick={handleSubmit(submitContentWhitelistRejectForm)}
        value="Reject Recommendation"
        className="max-w-20 cursor-pointer rounded-md bg-red-400 py-2 px-4 text-sm font-medium text-gray-900 shadow-sm hover:bg-red-500"
      />
      {error && <div className="mt-2 text-sm text-red-500">{error}</div>}
    </form>
  );
};

export default ContentWhitelistRejectForm;
