import { useLazyQuery, useMutation } from "@apollo/client";
import {
  ApiType,
  BRANDS_URL,
  Loading,
  logApiError,
  logApiSuccess,
  Spinner,
} from "@kalecard/common";
import { useContext, useEffect, useRef, useState } from "react";
import { useLocation } from "react-router";
import { UPDATE_BRAND_FACEBOOK_ACCESS_TOKEN } from "../../../graphql/mutations";
import {
  FACEBOOK_ACCOUNTS,
  GET_BRAND_FACEBOOK_ACCOUNTS,
} from "../../../graphql/queries";
import { UserContext } from "../../../providers/UserProvider";

interface FacebookRedirectParams {
  accessToken?: string;
  longLivedToken?: string;
  dataAccessExpirationTime?: string;
  error_reason?: string;
  error?: string;
  error_description?: string;
}

const facebookScopes = new Set<string>();
// Setting up access to instagram
facebookScopes.add("instagram_basic");
facebookScopes.add("pages_show_list");
facebookScopes.add("business_management");

// Manage messages
facebookScopes.add("instagram_manage_messages");

// Setting up webhooks
// https://developers.facebook.com/docs/instagram-api/guides/webhooks
facebookScopes.add("instagram_manage_insights");
// facebookScopes.add("instagram_manage_comments");
facebookScopes.add("pages_manage_metadata");

export default function FacebookConnect({ setRawFacebookAccounts }) {
  const { user, brandId } = useContext(UserContext);
  // For facebook review
  // if (user.uid === "Fvx1q2IZBWYb2aUTTRp9I6yrzm02") {
  //   facebookScopes.add("business_management");
  // }
  const [isLoading, setIsLoading] = useState(false);
  const [shouldUpdateFacebookToken, setShouldUpdateFacebookToken] =
    useState(true);
  const clientId = 1306235276797073;
  const redirectUri = `${BRANDS_URL}/settings/instagram`;
  const [updateFacebookAccessToken] = useMutation(
    UPDATE_BRAND_FACEBOOK_ACCESS_TOKEN
  );

  const { hash } = useLocation();
  const params = new URLSearchParams(hash);

  const queryCompleted = (data) => {
    console.log(data.getBrandFacebookAccounts);
    setRawFacebookAccounts(data.getBrandFacebookAccounts);
    setIsLoading(false);
  };

  const [getFacebookAccounts] = useLazyQuery(GET_BRAND_FACEBOOK_ACCOUNTS, {
    onCompleted: queryCompleted,
    variables: {
      brandId,
    },
  });

  // This connects to the Facebook account only returning the access token
  const responseFacebook = async (params: FacebookRedirectParams) => {
    setIsLoading(true);
    if (params.error) {
      // setErrorMessage("Failed to authenticate with Facebook");
      console.error(params);
      logApiError(
        ApiType.FACEBOOK,
        "facebook_login",
        "facebookLoginButton",
        params.error
      );
    } else {
      logApiSuccess(ApiType.FACEBOOK, "facebook_login", "facebookLoginButton");
      try {
        const result = await updateFacebookAccessToken({
          variables: {
            accessToken: params.longLivedToken ?? params.accessToken,
            scopes: Array.from(facebookScopes).join(","),
            domain: "brands.kalecard.com",
            brandId: brandId,
          },
          refetchQueries: [FACEBOOK_ACCOUNTS],
        });
        console.log(result);
        await getFacebookAccounts();
        logApiSuccess(
          ApiType.KALE,
          "updateFacebookAccessToken",
          "facebookLoginButton"
        );
      } catch (err) {
        console.error(err);
        logApiError(
          ApiType.KALE,
          "updateFacebookAccessToken",
          "facebookLoginButton",
          err.message
        );
        setIsLoading(false);
      }
    }
  };

  useEffect(() => {
    if (
      (params.has("long_lived_token") ||
        params.has("access_token") ||
        params.has("#access_token") ||
        params.has("#long_lived_token")) &&
      shouldUpdateFacebookToken
    ) {
      setShouldUpdateFacebookToken(false);
      const accessToken =
        params.get("access_token") ?? params.get("#access_token");
      const dataAccessExpirationTime = params.get(
        "data_access_expiration_time"
      );
      const longLivedToken =
        params.get("long_lived_token") ?? params.get("#long_lived_token");
      responseFacebook({
        accessToken,
        longLivedToken,
        dataAccessExpirationTime,
      });
    }
  }, [params]);

  if (isLoading) {
    return <Spinner size={"h-5 w-5"} />;
  }

  return (
    <>
      <div className="flex items-center space-x-1">
        <button
          className="rounded bg-fb-blue py-2 px-2 text-sm text-white shadow-lg hover:opacity-50 disabled:opacity-50"
          disabled={isLoading}
          onClick={() => {
            setIsLoading(true);
            window.location.replace(
              `https://www.facebook.com/v17.0/dialog/oauth?client_id=${clientId}&display=page&extras={"setup":{"channel":"IG_API_ONBOARDING"}}&redirect_uri=${redirectUri}&response_type=token&scope=${Array.from(
                facebookScopes
              ).join(",")}`
            );
          }}
        >
          Connect with Facebook
        </button>
        {isLoading && <Spinner size={"h-5 w-5"} />}
      </div>
    </>
  );
}
