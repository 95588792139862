import { Link, useLocation } from "react-router-dom";

interface SignUpFooterProps {
  isLogin: boolean;
  showLogin?: boolean;
  onButtonClick?: () => void;
}

export default function SignUpFooter({
  isLogin,
  showLogin = true,
  onButtonClick,
}: SignUpFooterProps) {
  const locationState: any = useLocation().state;
  const passowrdReset = (
    <div className="mt-2 text-xs">
      <Link
        className="hover:text-kale-orange-700 font-semibold text-kale-orange-500"
        to={{
          pathname: "/resetPassword",
          state: locationState?.from ? { from: locationState?.from } : null,
        }}
      >
        Forgot password?
      </Link>
    </div>
  );
  return (
    <>
      {!isLogin ? (
        <div className="relative inset-x-0 bottom-0 grid justify-center">
          {showLogin && (
            <div className="mt-2 flex text-xs">
              Already have an account?{" "}
              <Link
                className="hover:text-kale-orange-700 ml-2 font-semibold text-kale-orange-500"
                to={{
                  pathname: "/login",
                  state: locationState?.from
                    ? { from: locationState?.from }
                    : null,
                }}
              >
                <span onClick={onButtonClick}>Log in</span>
              </Link>
            </div>
          )}
          {passowrdReset}
        </div>
      ) : (
        <div className="relative inset-x-0 bottom-0 grid justify-center">
          <div className="mt-2 text-xs">
            Don't have an account yet?{" "}
            <Link
              className="hover:text-kale-orange-700 font-semibold text-kale-orange-500"
              to={{
                pathname: "/signup",
                state: locationState?.from
                  ? { from: locationState?.from }
                  : null,
              }}
            >
              <span onClick={onButtonClick}>Sign up</span>
            </Link>
          </div>
          {passowrdReset}
        </div>
      )}
    </>
  );
}
