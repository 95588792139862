import { Challenge, Post } from "@kalecard/common";

export const contentSortingTypes: ContentSortingType[] = [
  { name: "All", id: "KALE_PICKS" },
  { name: "Recent", id: "RECENT" },
  { name: "Most Viewed", id: "MOST_VIEWED" },
  { name: "Most Liked", id: "MOST_LIKED" },
];

export interface ContentSortingType {
  id: string;
  name: string;
}

export interface ContentState {
  content: Post[];
  hasNextPage?: boolean;
  after?: string;
  shouldUpdateContent?: boolean;
  sortingType?: ContentSortingType;
  challengeId?: string;
  selectableChallenges?: Challenge[];
}

export enum ContentActionType {
  SET_CONTENT,
  UPDATE_CONTENT,
  SET_SORTING_TYPE,
  ADD_CHALLENGE_FILTER,
  REMOVE_CHALLENGE_FILTER,
}

export interface ContentAction {
  type: ContentActionType;
  payload: ContentState;
}

export function ContentReducer(
  state: ContentState,
  action: ContentAction
): ContentState {
  switch (action.type) {
    case ContentActionType.SET_CONTENT: {
      const { content, hasNextPage } = action.payload;
      return {
        ...state,
        content: content,
        hasNextPage: hasNextPage,
        after: content.length.toString(),
        shouldUpdateContent: false,
      };
    }

    case ContentActionType.UPDATE_CONTENT: {
      const { content, hasNextPage } = action.payload;

      const updatedContent = [...state.content, ...content];
      return {
        ...state,
        content: updatedContent,
        hasNextPage: hasNextPage,
        after: updatedContent.length.toString(),
        shouldUpdateContent: false,
      };
    }

    case ContentActionType.SET_SORTING_TYPE: {
      const { sortingType } = action.payload;

      return {
        ...state,
        sortingType: sortingType,
        after: "0",
        shouldUpdateContent: true,
      };
    }

    case ContentActionType.ADD_CHALLENGE_FILTER: {
      const { challengeId } = action.payload;

      return {
        ...state,
        challengeId: challengeId,
        after: "0",
        shouldUpdateContent: true,
      };
    }

    case ContentActionType.REMOVE_CHALLENGE_FILTER: {
      return {
        ...state,
        challengeId: null,
        after: "0",
        shouldUpdateContent: true,
      };
    }
  }
}
