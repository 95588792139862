import {
  ContentActionType,
  ContentSortingType,
  contentSortingTypes,
} from "../../reducers/ContentReducer";
import { classNames } from "../../utils/style";

const setSortingType = (sortingType: ContentSortingType, contentDispatch) => {
  contentDispatch({
    type: ContentActionType.SET_SORTING_TYPE,
    payload: {
      sortingType: sortingType,
    },
  });
};

export const sortingTypes = (
  contentDispatch: React.Dispatch<{}>,
  sortingType?: ContentSortingType
) => {
  return (
    <div className="-mb-px flex space-x-8 pt-6">
      {contentSortingTypes.map((type) => {
        return (
          <div
            key={type.id}
            className={classNames(
              type.id === sortingType?.id
                ? "border-b-2 border-black text-black"
                : "border-gray-500 text-gray-500 hover:border-b-2 hover:text-gray-600",
              "pb-4 text-sm font-medium hover:cursor-pointer"
            )}
            onClick={() => setSortingType(type, contentDispatch)}
          >
            {type.name}
          </div>
        );
      })}
    </div>
  );
};
