import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import { useHistory, useParams } from "react-router";
import { useContext, useEffect, useRef, useState } from "react";
import { UserContext } from "../providers/UserProvider";
import { POST_COLLECTION, POST_COLLECTIONS } from "../graphql/queries";
import {
  DELETE_POST_COLLECTION,
  UPDATE_POST_COLLECTION,
} from "../graphql/mutations";
import { Modal, Spinner } from "@kalecard/common";
import { PencilSquareIcon, CheckIcon } from "@heroicons/react/24/outline";
import KaleBrandsHelmet from "../components/utils/KaleBrandsHelmet";
import ContentListComponent from "../components/lists/elements/ContentListComponent";
import { useIsVisible } from "react-is-visible";

export default function CollectionPage() {
  const { brandId } = useContext(UserContext);
  const params = useParams();
  const history = useHistory();
  const collectionId = params["id"];
  const [loading, setLoading] = useState(true);

  const [posts, setPosts] = useState([]);
  const [hasNextPage, setHasNextPage] = useState(true);
  const { data } = useQuery(POST_COLLECTION, {
    variables: {
      brandId: brandId,
      collectionId: collectionId,
    },
  });
  useEffect(() => {
    if (data?.postCollection) {
      if (loading) {
        setLoading(false);
      }
      const title = data?.postCollection?.title;
      if (title) {
        setTitleText(title);
      }
      const postEdges = data?.postCollection?.posts?.edges;
      if (postEdges) {
        setPosts(postEdges.map((edge) => edge.node));
      }
      const pageInfo = data?.postCollection?.posts?.pageInfo;
      if (pageInfo) {
        setHasNextPage(pageInfo.hasNextPage);
      }
    }
  }, [data]);

  const [getMorePosts] = useLazyQuery(POST_COLLECTION);

  const loadMorePosts = async (after: string) => {
    const result = await getMorePosts({
      variables: {
        brandId: brandId,
        collectionId: collectionId,
        after: after,
      },
    });
    return result.data;
  };
  const [loadingMore, setLoadingMore] = useState(false);
  const nodeRef = useRef(null);
  const isVisible = useIsVisible(nodeRef);
  const getMoreContent = async () => {
    if (!data || !hasNextPage || loadingMore) {
      return;
    }
    setLoadingMore(true);
    await loadMorePosts(`${posts.length}`).then((data) => {
      const newPosts = data?.postCollection?.posts?.edges;
      if (newPosts) {
        const postEdges = data?.postCollection?.posts?.edges;
        setPosts([...posts, ...postEdges.map((edge) => edge.node)]);
        const pageInfo = data?.postCollection?.posts?.pageInfo;
        if (pageInfo) {
          setHasNextPage(pageInfo.hasNextPage);
        }
      }
    });
    setLoadingMore(false);
  };
  useEffect(() => {
    if (isVisible && !loading && !loadingMore) {
      getMoreContent();
    }
  }, [isVisible]);

  const [editing, setEditing] = useState(false);
  const [titleText, setTitleText] = useState("");
  const [deletePostCollection] = useMutation(DELETE_POST_COLLECTION);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const deleteAndRedirect = () => {
    deletePostCollection({
      variables: {
        brandId: brandId,
        collectionId: collectionId,
      },
      refetchQueries: [POST_COLLECTIONS],
    }).then(() => {
      history.push("/content");
    });
  };
  const [updatePostCollection] = useMutation(UPDATE_POST_COLLECTION);
  const finishEditing = () => {
    updatePostCollection({
      variables: {
        brandId: brandId,
        collectionId: collectionId,
        title: titleText,
      },
    }).then(() => {
      setEditing(false);
    });
  };

  return (
    <>
      <KaleBrandsHelmet
        pageName={data?.postCollection?.title ?? "Collection"}
      />
      <div>
        {loading ? (
          <div className="flex justify-center">
            <Spinner size={"h-6 w-6"} />
          </div>
        ) : (
          <div className="mx-6 flex justify-between">
            <div className="flex flex-row font-semibold text-gray-900">
              {editing ? (
                <>
                  <input
                    type="text"
                    name="title"
                    value={titleText}
                    onChange={(e) => setTitleText(e.target.value)}
                    className="block w-full rounded-md border-gray-300 py-0 text-2xl focus:border-kale-green-500 focus:ring-kale-green-400"
                  />
                  <button
                    onClick={() => finishEditing()}
                    className="ml-2 inline-flex flex-col justify-center text-kale-green-500 hover:text-kale-green-400 focus:outline-none disabled:cursor-not-allowed disabled:text-gray-400 disabled:hover:text-gray-400"
                    disabled={titleText.length === 0}
                  >
                    <CheckIcon
                      className="h-6 w-6 flex-shrink-0 justify-self-center"
                      aria-hidden="true"
                    />
                  </button>
                </>
              ) : (
                <>
                  <h1 className="my-px text-2xl">{titleText}</h1>
                  <button
                    onClick={() => setEditing(!editing)}
                    className="ml-2 inline-flex flex-col justify-center text-kale-green-500 hover:text-kale-green-400 focus:outline-none"
                  >
                    <PencilSquareIcon
                      className="h-6 w-6 flex-shrink-0"
                      aria-hidden="true"
                    />
                  </button>
                </>
              )}
            </div>

            <button
              type="button"
              onClick={() => setDeleteModalOpen(true)}
              className="inline-flex items-center justify-center text-sm text-red-800 underline underline-offset-4"
            >
              Delete Collection
            </button>
          </div>
        )}
        <div className="mx-6 mt-6 border-t pt-4">
          <div>
            <ul
              role="list"
              className="flex flex-wrap justify-center md:justify-start"
            >
              {posts.length > 0 &&
                !loading &&
                posts.map((post, index) => (
                  <ContentListComponent
                    key={post.id + index}
                    post={post}
                    onContentWhitelistSubmit={null}
                  />
                ))}
              <li ref={nodeRef}></li>
            </ul>
          </div>

          <div className="max-w-7xl">
            {posts.length == 0 && !loading && !loadingMore && (
              <div>
                <p className="text-sm font-medium">Empty Collection</p>
                <p className="text-sm text-gray-900">
                  This collection is empty, go to the content page to add posts
                  here.
                </p>
              </div>
            )}
            {(loadingMore || loading) && (
              <div className="flex justify-center">
                <div className="flex flex-wrap content-center">
                  <Spinner size="h-6 w-6" />
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      <Modal
        isModalOpen={deleteModalOpen}
        onClose={() => setDeleteModalOpen(false)}
        className="w-full max-w-lg"
      >
        <div className="mx-5 my-2 space-y-5 text-gray-900">
          <h1 className="text-lg font-semibold">
            Are you sure you want to delete {titleText}? (This action is
            irreversible)
          </h1>
          <div className="text-md"></div>
          <div className="flex justify-end">
            <button
              className="rounded-md bg-red-500 p-2 text-white"
              onClick={() => deleteAndRedirect()}
            >
              Delete
            </button>
          </div>
        </div>
      </Modal>
    </>
  );
}
