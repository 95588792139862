import { Link } from "react-router-dom";

export default function ChallengesPausedBanner({
  onBannerClose,
}: {
  onBannerClose: () => void;
}) {
  return (
    <div className="fixed top-0 z-50 h-12 w-full">
      <div className="relative isolate flex items-center gap-x-6 overflow-hidden bg-gray-50 px-6 py-2.5 sm:px-3.5 sm:before:flex-1">
        <div
          className="absolute left-[max(-7rem,calc(50%-52rem))] top-1/2 -z-10 -translate-y-1/2 transform-gpu blur-2xl"
          aria-hidden="true"
        >
          <div className="aspect-[577/310] w-[36.0625rem] bg-gradient-to-r from-[#C5FFDF] to-[#FFDAE5]"></div>
        </div>
        <div
          className="absolute left-[max(45rem,calc(50%+8rem))] top-1/2 -z-10 -translate-y-1/2 transform-gpu blur-2xl"
          aria-hidden="true"
        >
          <div className="aspect-[577/310] w-[36.0625rem] bg-gradient-to-r from-[#C5FFDF] to-[#FFDAE5]"></div>
        </div>
        <div className="flex flex-wrap items-center gap-x-2 gap-y-2">
          <p className="text-sm leading-6 text-gray-900">
            <strong className="font-semibold">
              Your challenges are paused! To add more budget, visit the
            </strong>
          </p>
          <Link
            to="/billing/funds"
            className="flex-none rounded-full bg-gray-900 px-3.5 py-1 text-sm font-semibold text-white shadow-sm hover:bg-gray-700 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-900"
          >
            Billing
          </Link>
          <p className="text-sm leading-6 text-gray-900">
            <strong className="font-semibold">tab.</strong>
          </p>
        </div>
        <div className="flex flex-1 justify-end" onClick={onBannerClose}>
          <button
            type="button"
            className="-m-3 p-3 focus-visible:outline-offset-[-4px]"
          >
            <span className="sr-only">Dismiss</span>
            <svg
              className="h-5 w-5 text-gray-900"
              viewBox="0 0 20 20"
              fill="currentColor"
              aria-hidden="true"
            >
              <path d="M6.28 5.22a.75.75 0 00-1.06 1.06L8.94 10l-3.72 3.72a.75.75 0 101.06 1.06L10 11.06l3.72 3.72a.75.75 0 101.06-1.06L11.06 10l3.72-3.72a.75.75 0 00-1.06-1.06L10 8.94 6.28 5.22z" />
            </svg>
          </button>
        </div>
      </div>
    </div>
  );
}
