import { useContext } from "react";
import ProfilePage from "../../pages/ProfilePage";
import { UserContext } from "../../providers/UserProvider";
import UpdateBrandDetailsForm from "../forms/UpdateBrandDetailsForm";
import KaleBrandsHelmet from "../utils/KaleBrandsHelmet";
import { Description, Field, Label } from "../catalyst/fieldset";
import { BrandSetupStepButtonType } from "../setup/BrandSetupStepButton";

export default function GeneralSettingsPage() {
  const { user, brandId } = useContext(UserContext);

  const isSimplifiedView = user.uid === "Fvx1q2IZBWYb2aUTTRp9I6yrzm02";
  return (
    <>
      <KaleBrandsHelmet pageName="General Settings" />
      <div className="mx-auto max-w-7xl px-4 text-sm text-gray-700 sm:px-6 md:px-8">
        <div className="space-y-8 divide-y divide-gray-200 sm:space-y-5">
          <div className="space-y-6 sm:space-y-5">
            <div className="space-y-6 sm:space-y-5">
              {/* General settings */}
              {!isSimplifiedView && (
                <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:pt-5">
                  <Field>
                    <Label>General Settings</Label>
                    <Description>
                      Choose your logo and set your social usernames.
                    </Description>
                  </Field>

                  <div className="mt-1 flex w-full items-center sm:col-span-2 sm:mt-0">
                    <UpdateBrandDetailsForm
                      key={brandId}
                      buttonType={BrandSetupStepButtonType.SAVE}
                      onSave={() => {}}
                      showRequiredIndicator={false}
                      disableSaveOnIncomplete={false}
                    />
                  </div>
                </div>
              )}
              {/* Profile settings */}
              <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                <Field>
                  <Label>Profile</Label>
                </Field>

                <div className="mt-1 flex w-full items-center sm:col-span-2 sm:mt-0">
                  <ProfilePage />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
