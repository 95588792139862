type Props = {
  data: any;
};

const Table = ({ data }: Props) => {
  if (!data || !Array.isArray(data) || !data.length) return null;

  const headerKeys = Object.keys(data[0]);
  const headers = headerKeys.map((headerKey: string, index: number) => {
    let customStyle = "flex-1 justify-center";
    // Hardcoding for now, will allow customizing via props later
    if (index === 0 || index === 1) {
      customStyle = "w-40";
    } else if (index === 2) {
      customStyle = "w-52";
    }
    return (
      <th key={index} className={`flex px-2 font-medium ${customStyle}`}>
        {headerKey}
      </th>
    );
  });

  const rows = data.map((elem: any, elemIndex: number) => {
    const rowData = elem;
    return (
      <tr
        key={elemIndex}
        className={`flex flex-1 items-center ${
          elemIndex !== data.length - 1 && "border-b border-gray-200"
        } py-6`}
      >
        {headerKeys.map((headerKey: string, headerIndex: number) => {
          let customStyle = "flex-1 justify-center";
          // Hardcoding for now, will allow customizing via props later
          if (headerIndex === 0 || headerIndex === 1) {
            customStyle = "w-40";
          } else if (headerIndex === 2) {
            customStyle = "w-52";
          }
          return (
            <td
              key={headerIndex}
              className={`flex items-center overflow-x-hidden whitespace-nowrap px-2 ${customStyle}`}
            >
              {rowData[headerKey]}
            </td>
          );
        })}
      </tr>
    );
  });

  return (
    <table className="flex flex-col rounded-lg border border-gray-200 px-8 text-base">
      <thead className="flex">
        <tr className="flex h-[80px] flex-1 items-center border-b border-gray-200">
          {headers}
        </tr>
      </thead>
      <tbody>{rows}</tbody>
    </table>
  );
};
export default Table;
