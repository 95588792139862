import { useState } from "react";
import { CheckIcon, ChevronUpDownIcon } from "@heroicons/react/24/solid";

type DropdownValueType = {
  startTime: number;
  endTime: number;
  caption: string;
};

type DropdownItemType = {
  label: string | number;
  value: DropdownValueType;
};

type Props = {
  presetItems: DropdownItemType[];
  items: DropdownItemType[];
  selectedValue: any;
  setSelectedValue: any;
};

const Dropdown = ({
  presetItems,
  items,
  selectedValue,
  setSelectedValue,
}: Props) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className="relative text-base font-medium text-black">
      {isOpen && (
        <div className="absolute left-0 top-10 z-40 flex w-72 flex-col rounded-lg border border-gray-200 bg-white py-1.5 shadow-md">
          <div className="border-b border-gray-200 pt-1.5 pb-3 text-gray-500">
            {presetItems.map((presetItem, index) => {
              const isSelected = presetItem.value === selectedValue;
              return (
                <div
                  key={index}
                  onClick={() => {
                    setSelectedValue(presetItem.value);
                    setIsOpen(false);
                  }}
                  className="flex h-8 flex-1 cursor-pointer flex-row items-center space-x-1.5 py-1.5 px-4 hover:bg-gray-50"
                >
                  <div className="w-5">
                    {isSelected && <CheckIcon aria-hidden="true" />}
                  </div>
                  <div className={isSelected && "text-black"}>
                    {presetItem.label}
                  </div>
                </div>
              );
            })}
          </div>
          <div className="pt-1.5">
            {items.map((item, index) => (
              <div
                key={index}
                onClick={() => {
                  setSelectedValue(item.value);
                  setIsOpen(false);
                }}
                className="flex h-8 flex-1 cursor-pointer flex-row items-center space-x-1.5 py-1.5 px-4 hover:bg-gray-50"
              >
                <div className="w-5">
                  {item.value === selectedValue && (
                    <CheckIcon aria-hidden="true" />
                  )}
                </div>
                <div>{item.label}</div>
              </div>
            ))}
          </div>
        </div>
      )}
      <div className="flex h-8 w-fit overflow-hidden rounded-lg border border-gray-200 bg-white shadow-sm">
        <div
          className="flex w-36 cursor-pointer flex-row items-center justify-between border-r border-gray-200 bg-white px-3"
          onClick={() => {
            setIsOpen(!isOpen);
          }}
        >
          <div className="text-base font-medium text-black">
            {
              [...presetItems, ...items].find(
                (item) => item.value === selectedValue
              )?.label
            }
          </div>
          <ChevronUpDownIcon className="h-5 text-black" aria-hidden="true" />
        </div>
        <div className="flex items-center pl-3 pr-4">
          <div>{selectedValue.caption}</div>
        </div>
      </div>
    </div>
  );
};

export default Dropdown;
