import gql from "graphql-tag";

export const COLLECTIONS_OF_POST_FRAGMENT = gql`
  fragment PostCollectionsFragment on Post {
    id
    collections(brandId: $brandId) {
      id
      title
    }
  }
`;
