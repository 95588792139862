import { useQuery } from '@apollo/client';
import BlockUsernamesForm from '../forms/BlockUsernamesForm';
import { BLOCKED_USERS } from '../../graphql/queries';
import { useContext, useRef, useState } from 'react';
import { UserContext } from '../../providers/UserProvider';
import { ArrowDownTrayIcon } from '@heroicons/react/24/outline';
import CSVBlockedUsers from '../utils/BlockedUsersCSV';
import InfluencerPosts from './InfluencerPosts';
import { Subheading } from '../catalyst/heading';
import { Button } from '../catalyst/button';

export default function InfluencerBlockList() {
  const { brandId } = useContext(UserContext);
  const csvLink = useRef(null);
  const {
    data: blockedUsersData,
    loading: blockedUsersLoading,
    refetch,
  } = useQuery(BLOCKED_USERS, {
    variables: { brandId: brandId },
  });
  return (
    <div className="space-y-6 divide-y divide-gray-200">
      <div>
        <Subheading>Block users from posting for your brand</Subheading>
        <BlockUsernamesForm refetchBlockedUsers={refetch} />
        {blockedUsersData?.blockedUsers?.length > 0 && !blockedUsersLoading && (
          <div className="flex items-center space-x-4 pt-4">
            <p className="text-sm font-medium text-gray-900">
              View your blocked users:
            </p>
            <Button
              color="emerald"
              onClick={() => csvLink.current.link.click()}
            >
              <ArrowDownTrayIcon />
              Download CSV
            </Button>
          </div>
        )}
        <CSVBlockedUsers
          blockedUsers={blockedUsersData?.blockedUsers ?? []}
          csvRef={csvLink}
        />
      </div>
      <div className="pt-6">
        <InfluencerPosts />
      </div>
    </div>
  );
}
