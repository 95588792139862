import { ActionType, logAction } from "@kalecard/common";
import { SignUpInputs } from "../components/forms/SignUpForm";
import { auth } from "../config/firebase";

export enum SignUpState {
  LOADING = "loading",
  SIGNUP = "signup",
  LOGIN = "login",
}

export const onSignUpSubmit = async (
  screenName: string,
  data: SignUpInputs,
  setState: (state: SignUpState) => void,
  setFormData: (data: SignUpInputs) => void,
  setError: (state: string) => void,
  redirect: (path: string) => void,
  brandUserSignUp
) => {
  const componentName = "SignUpForm";
  setFormData(data);
  setState(SignUpState.LOADING);

  logAction(ActionType.SUBMIT, componentName, {
    name: data.name,
    email: data.email,
    password: data.password,
    companyTitle: data.companyTitle,
  });

  try {
    await auth
      .createUserWithEmailAndPassword(data.email, data.password)
      .then(async (userCredential) => {
        const signUpResult = await brandUserSignUp({
          variables: {
            name: data.name,
            email: userCredential.user.email,
            companyTitle: data.companyTitle,
          },
        });
        console.log(signUpResult);
        redirect("/settings");
      });
  } catch (error: any) {
    console.log(error);
    setError(error.message);
    setState(SignUpState.SIGNUP);
  }
};

export const onLoginSubmit = async (
  screenName: string,
  data: SignUpInputs,
  setState: (state: SignUpState) => void,
  setFormData: (data: SignUpInputs) => void,
  setError: (state: string) => void,
  redirect: (path: string) => void
) => {
  const componentName = "LoginForm";

  console.log(data);
  setFormData(data);
  setState(SignUpState.LOADING);

  logAction(ActionType.SUBMIT, componentName, {
    name: data.name,
    email: data.email,
    password: data.password,
  });

  try {
    const userCredential = await auth.signInWithEmailAndPassword(
      data.email,
      data.password
    );
    console.log("User: ", userCredential);
    redirect("/content");
  } catch (error: any) {
    console.log(error);
    setError(error.message);
    setState(SignUpState.LOGIN);
  }
};
