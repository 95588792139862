import {
  numberWithCommas,
  InstagramLogo,
  TiktokLogo,
  BonsaiOutcome,
  Dropdown,
} from "@kalecard/common";

import {
  ChatBubbleOvalLeftIcon,
  HeartIcon,
  EyeIcon,
} from "@heroicons/react/24/outline";
import { XMarkIcon, CheckIcon } from "@heroicons/react/24/solid";
import MediaComponent from "../../MediaComponent";
import {
  BrandReviewBundleMetadata,
  BrandReviewOutput,
  VideoUploadReviewBundleMetadata,
  RejectionReason, RequirementItem,
} from "../BonsaiModels";
import { useState } from "react";
import { Dialog, DialogActions, DialogBody } from "../../catalyst/dialog";
import { Checkbox, CheckboxField } from "../../catalyst/checkbox";
import { Label } from "../../catalyst/fieldset";
import { Button } from "../../catalyst/button";
import { DialogTitle } from "@headlessui/react";

const ReviewPanel = ({
  challengeId,
  challengeTitle,
  challengeDescription,
  videoUrl,
  postedBy,
  postExternalUrl,
  postExternalPlatform,
  postCaption,
  likes,
  comments,
  impressions,
  rejectionReasons,
  requirements,
  onComplete,
  videosRemaining,
}: {
  challengeId: number;
  challengeTitle: string;
  challengeDescription: string;
  videoUrl: string;
  postedBy?: string;
  postExternalUrl?: string;
  postExternalPlatform?: string;
  postCaption?: string;
  likes?: number;
  comments?: number;
  impressions?: number;
  rejectionReasons: RejectionReason[];
  requirements?: RequirementItem[];
  onComplete: (outcome: BrandReviewOutput) => void;
  videosRemaining: number;
}) => {
  const [selectedOutcome, setSelectedOutcome] = useState<
    BonsaiOutcome | undefined
  >(undefined);

  const [note, setNote] = useState<string>("");
  const [selectedRejectionReasonId, setSelectedRejectionReasonId] =
    useState<string>(null);

  const [checked, setChecked] = useState<Set<string>>(new Set());
  const [requirementsDialogOpen, setRequirementsDialogOpen] = useState(false);
  const REQUIREMENTS_NOT_MET_REJECTION_REASON = "REQUIREMENTS_NOT_MET";
  const translateCheckedToRequirements = (checked: Set<string>) => {
    return Array.from(checked).map((id) => {
      return requirements.find((requirement) => requirement.id === id)
        .name;
    });
  };

  const resetSelections = () => {
    setSelectedOutcome(undefined);
    setNote("");
    setSelectedRejectionReasonId(null);
  };

  const openChallengeDescription = (challengeId) => {
    window.open(`/challenges/${challengeId}`, "_blank");
  };

  return (
    <div className="flex flex-col">
      <div className="w-full text-center font-bold">
        {`Videos Remaining: ${videosRemaining}`}
      </div>
      {challengeId ? (
        <div className="mt-2 flex flex-col items-center justify-center space-y-2 p-2 underline underline-offset-4">
          <a
            className="max-w-[500px] text-center text-sm text-kale-green-500 hover:cursor-pointer hover:text-kale-green-400"
            onClick={() => openChallengeDescription(challengeId)}
          >
            <p className="line-clamp-2 text-ellipsis">
              {challengeTitle ? challengeTitle : "Challenge"}
              {": "}
              {challengeDescription}
            </p>
          </a>
        </div>
      ) : null}
      <div className="space-ar mb-60 flex justify-center">
        <div className="m-2 rounded-lg bg-white text-center shadow">
          <div className="flex flex-col flex-col items-center justify-center space-y-2 p-2">
            <MediaComponent url={videoUrl || ""} mediaType={"VIDEO"} />
            <div className="flex h-full flex-col justify-between space-y-1 border-b pb-1">
              <Caption
                username={postedBy}
                externalUrl={postExternalUrl}
                externalPlatform={postExternalPlatform}
                caption={postCaption}
              />
              {likes || comments || impressions ? (
                <PostStats
                  likes={likes}
                  comments={comments}
                  impressions={impressions}
                />
              ) : null}
            </div>
            <div className="mt-2 flex h-8 w-full space-x-2">
              <button
                onClick={() => setSelectedOutcome(BonsaiOutcome.REJECTED)}
                className={`flex flex-1 items-center justify-center rounded-md border border-red-500 text-red-500 hover:bg-red-400 hover:text-white ${
                  selectedOutcome === BonsaiOutcome.REJECTED
                    ? "bg-red-500 text-white"
                    : "bg-white text-black"
                }`}
              >
                <XMarkIcon className="h-5 w-5" />
              </button>
              <button
                onClick={() => setSelectedOutcome(BonsaiOutcome.ACCEPTED)}
                className={`flex flex-1 items-center justify-center rounded-md border border-green-500 text-green-500 hover:bg-green-400 hover:text-white ${
                  selectedOutcome === BonsaiOutcome.ACCEPTED
                    ? "bg-green-500 text-white"
                    : "bg-white text-black"
                }
                `}
              >
                <CheckIcon className="h-5 w-5" />
              </button>
            </div>
            <div className="mt-2 w-80">
              {selectedOutcome === BonsaiOutcome.REJECTED ? (
                rejectionReasons && rejectionReasons.length > 0 ? (
                  <div>
                    <p className="text-left text-sm">Rejection Reason:</p>
                    <Dropdown
                      options={rejectionReasons.map((reason) => ({
                        id: reason.id,
                        name: reason.name,
                        imageUrl: null,
                      }))}
                      activeOptionId={selectedRejectionReasonId}
                      optionClicked={(option) => {
                        setSelectedRejectionReasonId(option.id);
                      }}
                      label={""}
                    />
                  </div>
                ) : (
                  <textarea
                    className="mt-4 w-full rounded-md border p-2"
                    placeholder="Add a note..."
                    value={note}
                    onChange={(e) => setNote(e.target.value)}
                  />
                )
              ) : null}
              {requirementsDialogOpen && <Dialog
                open={requirementsDialogOpen}
                onClose={() => {
                  setRequirementsDialogOpen(false);
                  setChecked(new Set());
                }}
                size="md"
              >
                <DialogTitle>
                  Which requirements were not met? (Select all that apply):
                </DialogTitle>
                <DialogBody>
                  {requirements.map((requirement) => (
                    <CheckboxField key={requirement.id}>
                      <Checkbox
                        name="requirements"
                        color="green"
                        checked={checked.has(requirement.id)}
                        onChange={() => {
                          let newChecked = new Set(checked);
                          if (newChecked.has(requirement.id)) {
                            newChecked.delete(requirement.id);
                          } else {
                            newChecked.add(requirement.id);
                          }
                          setChecked(newChecked);
                        }}
                      />
                      <Label>{requirement.name}</Label>
                    </CheckboxField>
                  ))}
                </DialogBody>
                <DialogActions>
                  <Button
                    plain
                    onClick={() => {
                      setRequirementsDialogOpen(false);
                      setChecked(new Set());
                    }}
                  >
                    Cancel
                  </Button>
                  <Button
                    color="green"
                    onClick={() => {
                      onComplete({
                        outcome: BonsaiOutcome.REJECTED,
                        rejectionReasonId: REQUIREMENTS_NOT_MET_REJECTION_REASON,
                        unmetRequirements: translateCheckedToRequirements(checked),
                      });
                      setRequirementsDialogOpen(false);
                      setChecked(new Set());
                    }}
                    disabled={checked.size === 0}
                  >
                    Submit
                  </Button>
                </DialogActions>
              </Dialog>}
              <div className="mt-2">
                <button
                  onClick={() => {
                    if (
                      selectedOutcome === BonsaiOutcome.REJECTED &&
                      selectedRejectionReasonId === REQUIREMENTS_NOT_MET_REJECTION_REASON
                    ) {
                      setRequirementsDialogOpen(true);
                      return;
                    }
                    onComplete({
                      outcome: selectedOutcome,
                      note,
                      rejectionReasonId: selectedRejectionReasonId,
                    });
                    resetSelections();
                  }}
                  className="w-full items-center justify-center rounded-md bg-blue-500 px-4 py-2 text-white hover:bg-blue-600 disabled:cursor-not-allowed disabled:bg-gray-400"
                  disabled={
                    selectedOutcome === undefined ||
                    (selectedOutcome === BonsaiOutcome.REJECTED &&
                      note.length === 0 &&
                      selectedRejectionReasonId === null)
                  }
                >
                  Submit
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const Caption = ({
  username,
  externalUrl,
  externalPlatform,
  caption,
}: {
  username: string;
  externalUrl: string;
  externalPlatform: string;
  caption: string;
}) => (
  <div className="w-[260px] px-1 py-1 text-left text-xs text-kale-green-1000">
    {username && externalPlatform ? (
      <div className="pb-1">
        <a
          className="py-1 text-sm font-semibold text-black hover:cursor-pointer hover:text-kale-green-300"
          href={
            externalPlatform === "TIKTOK"
              ? "https://tiktok.com/@" + username
              : "https://instagram.com/" + username
          }
          target="_blank"
          rel="noreferrer"
        >
          @<span>{username}</span>
        </a>
      </div>
    ) : (
      <div className="pb-1">
        <p className="text-sm text-black">Caption:</p>
      </div>
    )}
    <a href={externalUrl} target="_blank">
      <span
        className={`flex items-start space-x-1 ${
          externalUrl ? "hover:text-kale-green-300" : ""
        }`}
      >
        {externalPlatform ? (
          externalPlatform === "INSTAGRAM" ? (
            <span>
              <InstagramLogo />
            </span>
          ) : (
            <span>
              <TiktokLogo />
            </span>
          )
        ) : null}
        <span>{caption}</span>
      </span>
    </a>
  </div>
);

const PostStats = ({
  likes,
  comments,
  impressions,
}: {
  likes: number;
  comments: number;
  impressions: number;
}) => (
  <div className="flex w-full justify-between px-1 text-sm">
    <div className="flex items-center space-x-1">
      <HeartIcon className="h-4 w-4" />
      <p>{numberWithCommas(likes)}</p>
    </div>
    <div className="flex items-center space-x-1">
      <ChatBubbleOvalLeftIcon className="h-4 w-4" />
      <p>{numberWithCommas(comments)}</p>
    </div>
    <div className="flex items-center space-x-1">
      <EyeIcon className="h-4 w-4" />
      <p>{numberWithCommas(impressions)}</p>
    </div>
  </div>
);

export const VideoUploadReviewPanel = ({
  metadata,
  onComplete,
  videosRemaining,
}: {
  metadata: VideoUploadReviewBundleMetadata;
  onComplete: (outcome: BrandReviewOutput) => void;
  videosRemaining: number;
}) => (
  <ReviewPanel
    challengeId={metadata.challengeId}
    challengeTitle={metadata.challengeTitle}
    challengeDescription={metadata.challengeDescription}
    videoUrl={metadata.videoUrl}
    rejectionReasons={metadata.rejectionReasons}
    requirements={metadata.requirements}
    postCaption={metadata.caption}
    onComplete={onComplete}
    videosRemaining={videosRemaining}
  />
);

export const StandardReviewPanel = ({
  metadata,
  onComplete,
  videosRemaining,
}: {
  metadata: BrandReviewBundleMetadata;
  onComplete: (outcome: BrandReviewOutput) => void;
  videosRemaining: number;
}) => (
  <ReviewPanel
    challengeId={metadata.challengeId}
    challengeTitle={metadata.challengeTitle}
    challengeDescription={metadata.challengeDescription}
    videoUrl={metadata.postUrl}
    postedBy={metadata.postedBy}
    postExternalUrl={metadata.postExternalUrl}
    postExternalPlatform={metadata.postExternalPlatform}
    postCaption={metadata.postCaption}
    likes={metadata.likes}
    comments={metadata.comments}
    impressions={metadata.impressions}
    rejectionReasons={metadata.rejectionReasons}
    onComplete={onComplete}
    videosRemaining={videosRemaining}
  />
);