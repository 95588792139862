import { createContext, useReducer } from "react";
import {
  ChallengesReducer,
  ChallengesState,
} from "../reducers/ChallengesReducer";

export const ChallengesContext = createContext<{
  state: ChallengesState;
  dispatch: React.Dispatch<{}>;
}>(null);

export default function ChallengesProvider({
  challengesState,
  children,
}: {
  challengesState?: ChallengesState;
  children?: any;
}) {
  const [state, dispatch] = useReducer(
    ChallengesReducer,
    getInitialState(challengesState)
  );

  return (
    <ChallengesContext.Provider value={{ state: state, dispatch }}>
      {children}
    </ChallengesContext.Provider>
  );
}

function getInitialState(challengesState: ChallengesState): ChallengesState {
  return {
    challenges: challengesState.challenges || [],
    hasNextPage: challengesState.hasNextPage,
    after: challengesState.after,
    shouldUpdateChallenges: challengesState.shouldUpdateChallenges,
    pendingChallenges: challengesState.pendingChallenges || [],
  };
}
