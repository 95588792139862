import { ApolloProvider } from "@apollo/client";
import "./App.css";
import apolloClient from "./config/apolloClient";
import Application from "./pages/Application";
import UserProvider from "./providers/UserProvider";
import { BrowserRouter } from "react-router-dom";

function App() {
  return (
    <ApolloProvider client={apolloClient}>
      <BrowserRouter>
        <UserProvider>
          <Application />
        </UserProvider>
      </BrowserRouter>
    </ApolloProvider>
  );
}

export default App;
