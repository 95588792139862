import { Helmet } from "react-helmet";

export default function KaleBrandsHelmet({ pageName }: { pageName: string }) {
  return (
    <Helmet>
      <meta charSet="utf-8" />
      <title>Kale | {pageName}</title>
      <meta property="og:title" content="Kale" />
      <meta property="og:type" content="website" />
    </Helmet>
  );
}