export const EXAMPLE = new Set(["<user.uid here>"]);

export const BRAND_REVIEW_ALLOW_LIST_BRAND_IDS = new Set([
  // Bubble
  "528",

  // Starry
  "620",

  // Cinnabon
  "654",

  // Kale Test
  "246",
]);

export const isDev = process.env.NODE_ENV === "development";
