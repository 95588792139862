import {
  Brand,
  Challenge,
  Spinner,
} from "@kalecard/common";
import { useContext, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { NEW_CHALLENGE_FEEDBACK } from "../../graphql/mutations";
import { useMutation } from "@apollo/client";
import { ChallengesActionType } from "../../reducers/ChallengesReducer";
import { ChallengesContext } from "../../providers/ChallengesProvider";
import DatePicker from "react-datepicker";

interface ChallengeFeedbackFormInterface {
  brand: Brand;
  challenge: Challenge;
  requestingActivationOrDeactivation: boolean;
  buttonCTA: string;
  closeModal?: () => void;
}

type ChallengeFeedbackFormInputs = {
  feedback?: string;
};

export default function ChallengeFeedbackForm({
  brand,
  challenge,
  requestingActivationOrDeactivation,
  buttonCTA,
  closeModal
}: ChallengeFeedbackFormInterface) {
  const [isLoading, setIsLoading] = useState(false);
  const { reset, register, handleSubmit } =
    useForm<ChallengeFeedbackFormInputs>();
  const [newChallengeFeedback] = useMutation(NEW_CHALLENGE_FEEDBACK);
  const [successMessage, setSuccessMessage] = useState(null);
  const [statusChangeDate, setStatusChangeDate] = useState<Date>(null);
  const today = new Date();
  const { dispatch } = useContext(ChallengesContext);

  const submit: SubmitHandler<ChallengeFeedbackFormInputs> = async (
    data: ChallengeFeedbackFormInputs
  ) => {
    setIsLoading(true);
    try {
      const result = await newChallengeFeedback({
        variables: {
          brandId: brand.id,
          challengeId: challenge.id,
          feedback: requestingActivationOrDeactivation ? `${buttonCTA}${statusChangeDate ? ` for ${statusChangeDate.toLocaleDateString('en-US')}` : ''}` : data.feedback,
        },
      });
      console.log(result);
      if (!requestingActivationOrDeactivation) {
        setSuccessMessage(
          "Your feedback has been submitted! Kale's Creative Team will address it soon."
        );
      }
      dispatch({
        type: ChallengesActionType.ADD_FEEDBACK,
        payload: challenge,
      });
      reset();
    } catch (err) {
      console.log(err);
    }
    setIsLoading(false);
  };

  return (
    <form className={`${requestingActivationOrDeactivation ? "" : "w-full"} space-y-2`}>
      <div className="pb-2 text-sm text-gray-700">
        <p className="font-medium">Challenge:</p>
        {challenge.description}
      </div>
      <div className="flex flex-col space-y-2">
        {/* Feedback */}
        {!requestingActivationOrDeactivation && (<div>
          <label
            htmlFor="feedback"
            className="block text-sm font-medium text-gray-700"
          >
            Feedback:
          </label>
          <div className="mt-1 flex flex-col">
            <div className="flex flex-row">
              <textarea
                rows={3}
                {...register("feedback")}
                name="feedback"
                id="feedback"
                className="block w-full flex-1 rounded-md border-gray-300 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
              />
            </div>
          </div>
        </div>)}

        <div className={`flex ${requestingActivationOrDeactivation ? "justify-center" : "justify-end pt-5"}`}>
          <div className="flex flex-col items-center">
            <div className="flex items-center space-x-2">
              {isLoading && !requestingActivationOrDeactivation && (
                <div className="flex flex-wrap content-center justify-center">
                  <Spinner size="h-6 w-6" />
                </div>
              )}
              {requestingActivationOrDeactivation && <div className="flex flex-col space-y-2">
                <div>
                  <label
                    htmlFor="month"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Date:
                  </label>
                  <DatePicker
                    className="mt-1 rounded-md border-gray-300 text-sm shadow-sm w-[100px]"
                    selected={statusChangeDate}
                    onChange={(date) => setStatusChangeDate(date)
                    }
                    dateFormat="MM/dd/yyyy"
                    minDate={today}
                  />
                </div>
              </div>}
              <div className="flex justify-between pt-6 pl-12">
                <button
                  type="button"
                  onClick={handleSubmit(async (data) => {
                    closeModal && closeModal();
                    await submit(data);
                  })}
                  disabled={isLoading}
                  className="inline-flex justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 disabled:bg-indigo-400"
                >
                  Submit {buttonCTA}
                </button>
              </div>
            </div>
            {successMessage && (
              <div className="pt-2 text-sm font-medium text-green-600">
                {successMessage}
              </div>
            )}
          </div>
        </div>
      </div>
    </form>
  );
}
