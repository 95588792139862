import { useFragment, useMutation, useQuery } from "@apollo/client";
import { COLLECTIONS_OF_POST_FRAGMENT } from "../../graphql/fragments";
import { useContext, useEffect, useState } from "react";
import { POST_COLLECTION, POST_COLLECTIONS } from "../../graphql/queries";
import { UserContext } from "../../providers/UserProvider";
import {
  CREATE_POST_COLLECTION,
  UPDATE_POST_COLLECTION_MEMBERSHIP,
} from "../../graphql/mutations";
import { PlusIcon } from "@heroicons/react/24/solid";
import { Spinner } from "@kalecard/common";

export default function PostCollectionsEditorForm({
  postId,
  closeModal,
}: {
  postId?: string;
  closeModal: () => void;
}) {
  const isCreateOnlyMode = postId === undefined;
  const { brandId } = useContext(UserContext);
  const { data: postData } = useFragment({
    fragment: COLLECTIONS_OF_POST_FRAGMENT,
    from: {
      __typename: "Post",
      id: postId,
    },
    variables: {
      brandId: brandId,
    },
  });

  const [selectedCollections, setSelectedCollections] = useState<Set<string>>(
    new Set()
  );
  const [modifiedCollections, setModifiedCollections] = useState<Set<string>>(
    new Set()
  );
  useEffect(() => {
    if (postData?.collections) {
      setSelectedCollections(
        new Set(postData.collections.map((collection) => collection.id))
      );
    }
  }, [postData]);
  const { data: allPostCollectionsData } = useQuery(POST_COLLECTIONS, {
    variables: {
      brandId: brandId,
    },
  });
  const [updateMembership] = useMutation(UPDATE_POST_COLLECTION_MEMBERSHIP);
  const toggleSelectedCollections = (collectionId) => {
    const newSelections = new Set(selectedCollections);
    if (selectedCollections.has(collectionId)) {
      newSelections.delete(collectionId);
    } else {
      newSelections.add(collectionId);
    }
    setSelectedCollections(newSelections);

    const newModifiedCollections = new Set(modifiedCollections);
    newModifiedCollections.add(collectionId);
    setModifiedCollections(newModifiedCollections);
  };

  const [isLoading, setIsLoading] = useState(false);
  const saveChanges = async () => {
    const listOfChangedCollections = Array.from(modifiedCollections);
    if (listOfChangedCollections.length > 0) {
      setIsLoading(true);
      await updateMembership({
        variables: {
          brandId: brandId,
          postId: postId,
          includedCollections: Array.from(selectedCollections),
          excludedCollections: listOfChangedCollections.filter(
            (id) => !selectedCollections.has(id)
          ),
        },
        refetchQueries: [
          ...listOfChangedCollections.map((id) => ({
            query: POST_COLLECTION,
            variables: {
              brandId: brandId,
              collectionId: id,
            },
          })),
        ],
      });
      setIsLoading(false);
      closeModal();
    }
  };

  const [creatingMode, setCreatingMode] = useState(isCreateOnlyMode);
  const [createPostCollection] = useMutation(CREATE_POST_COLLECTION);
  const [newCollectionTitle, setNewCollectionTitle] = useState("");
  const submitNewPostCollection = async () => {
    if (newCollectionTitle.length === 0) {
      return;
    }

    setIsLoading(true);
    await createPostCollection({
      variables: {
        brandId: brandId,
        title: newCollectionTitle,
      },
      refetchQueries: [POST_COLLECTIONS],
    });
    setIsLoading(false);
    setNewCollectionTitle("");
  };

  return (
    <div className="flex h-full flex-col space-y-4 divide-y-2 divide-gray-200">
      <h1 className="flex flex-row text-xl font-medium leading-6 text-gray-900">
        {isCreateOnlyMode ? "Create a collection" : "Save to collection"}
        {isLoading && (
          <div className="ml-2">
            <Spinner size="h-6 w-6" />
          </div>
        )}
      </h1>
      {allPostCollectionsData?.postCollections?.edges?.length === 0 ? null : (
        <div className="px-2 pt-4">
          {allPostCollectionsData?.postCollections?.edges?.map((edge) => (
            <div
              key={edge.node.id}
              className="flex items-center justify-start py-2"
            >
              {isCreateOnlyMode ? null : (
                <input
                  id="isEnabled"
                  name="isEnabled"
                  type="checkbox"
                  className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
                  checked={selectedCollections.has(edge.node.id)}
                  onChange={() => {
                    setCreatingMode(false);
                    toggleSelectedCollections(edge.node.id);
                  }}
                />
              )}
              <div className="ml-2">{edge.node.title}</div>
            </div>
          ))}
        </div>
      )}
      <div className="flex items-center justify-between px-2 pt-4 pb-2">
        {creatingMode ? (
          <input
            type="text"
            name="title"
            className="text-md block w-80 rounded-md border-gray-300 px-3 focus:border-kale-green-500 focus:ring-kale-green-400 disabled:bg-gray-100 disabled:text-gray-600"
            value={newCollectionTitle}
            placeholder="New Collection Name"
            onChange={(e) => setNewCollectionTitle(e.target.value)}
          />
        ) : (
          <button
            onClick={() => setCreatingMode(true)}
            className="inline-flex flex-row items-center space-x-2 font-bold text-kale-green-500 hover:text-kale-green-400 focus:outline-none"
          >
            <PlusIcon className="h-5 w-5" />
            <span>Create new collection</span>
          </button>
        )}
        {creatingMode ? (
          <button
            type="submit"
            onClick={submitNewPostCollection}
            className="inline-flex justify-center rounded-md border border-transparent bg-kale-green-500 px-3 py-1 font-semibold text-white shadow-sm hover:bg-kale-green-400 focus:outline-none focus:ring-2 focus:ring-kale-green-400 focus:ring-offset-2 disabled:bg-kale-green-300"
            disabled={newCollectionTitle.length === 0}
          >
            Create
          </button>
        ) : (
          <button
            type="submit"
            onClick={saveChanges}
            className="inline-flex justify-center rounded-md border border-transparent bg-kale-green-500 px-3 py-1 font-semibold text-white shadow-sm hover:bg-kale-green-400 focus:outline-none focus:ring-2 focus:ring-kale-green-400 focus:ring-offset-2 disabled:bg-kale-green-300"
            disabled={modifiedCollections.size === 0}
          >
            Save
          </button>
        )}
      </div>
    </div>
  );
}
