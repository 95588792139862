import Footer from "../components/Footer";
import KaleLogo from "../images/kale-logo.svg";

export default function OnboardingSkeleton(props) {
  return (
    <>
      <div className="bg-white flex">
        <div className="flex flex-col w-full justify-between pt-12 px-4">
          <div className="">
            <div className="flex justify-center mb-4">
              <img
                className="h-16 w-16 bg-kale-mint-500 rounded-full border-kale-green-500 p-1"
                src={KaleLogo}
                alt="Kale"
              />
            </div>
            {props.children}
          </div>
          <div className="pt-10">
            <Footer />
          </div>
        </div>
      </div>
    </>
  );
}
