import { useMutation } from "@apollo/client";
import { Media, timeDifferenceForDate } from "@kalecard/common";
import { useState } from "react";
import {
  COMMENT_ON_MEDIA,
  REPLY_TO_MENTIONED_MEDIA,
} from "../../../graphql/mutations";
import MediaComponent from "../../MediaComponent";

interface InstagramMediaComponentInterface {
  media: Media;
  brandId: String;
  facebookAccountId: String;
  enableCommentOnMedia: Boolean;
  displayMedia?: Boolean;
  isMentionedMedia?: Boolean;
}

export default function InstagramMediaComponent({
  media,
  brandId,
  facebookAccountId,
  enableCommentOnMedia,
  displayMedia = false,
  isMentionedMedia = false,
}: InstagramMediaComponentInterface) {
  const captionLink = (
    <a
      className="truncate text-sm font-semibold text-indigo-600 hover:text-indigo-900"
      href={media.permalink}
      target="_blank"
      rel="noreferrer"
    >
      {media.caption ? media.caption : "(empty caption)"}
    </a>
  );

  const [responseMessages, setResponseMessages] = useState(new Map());
  const [commentErrorMessage, setCommentErrorMessage] = useState("");

  const updateResponseMessages = (externalMediaId, content) => {
    const updatedResponseMessages = new Map(responseMessages);
    updatedResponseMessages.set(externalMediaId, content);
    setResponseMessages(updatedResponseMessages);
  };

  const [commentOnMedia] = useMutation(COMMENT_ON_MEDIA);
  const [replyToMentionedMedia] = useMutation(REPLY_TO_MENTIONED_MEDIA);

  const respondToPost = async (externalMediaId, brandId) => {
    const message = responseMessages.get(externalMediaId);
    console.log(
      `Responding to ${externalMediaId} for ${brandId} fbAcctId=${facebookAccountId}: ${message}`
    );
    await sendComment(externalMediaId, brandId, message)
      .then((result) => {
        console.log(result);
        updateResponseMessages(externalMediaId, "");
        setCommentErrorMessage("");
      })
      .catch((error) => {
        setCommentErrorMessage("Failed to post comment, try again later.");
      });
  };

  return (
    <tr>
      {displayMedia && (
        <td className="w-[200px] max-w-xs truncate px-6 py-4">
          <MediaComponent
            url={media.mediaUrl}
            mediaType={media.mediaType || "VIDEO"}
            showControls={false}
            width={200}
            height={340}
          />
        </td>
      )}
      <td className="max-w-xs truncate px-6 py-4">
        {captionLink}
        <div className="text-sm text-gray-500">
          {timeDifferenceForDate(Number(media.createdAt))} -{" "}
          <a
            className="text-sm font-semibold text-indigo-600 hover:text-indigo-900"
            href={media.mediaUrl}
            target="_blank"
            rel="noreferrer"
          >
            View media
          </a>
        </div>
      </td>
      {enableCommentOnMedia && (
        <td className="whitespace-nowrap px-6 py-4 text-sm">
          <div className="justify-middle flex items-center">
            <textarea
              placeholder="Write your comment here"
              value={responseMessages.get(media.id)}
              onChange={(e) => {
                updateResponseMessages(media.id, e.target.value);
              }}
              className="w-full"
            ></textarea>
          </div>
          <div>
            <button
              onClick={() => {
                respondToPost(media.id, brandId);
              }}
              className="place-self-end rounded-br-lg border border-transparent"
            >
              <u>Post Comment</u>
            </button>
          </div>
          <div className="text-red-400">{commentErrorMessage}</div>
        </td>
      )}
      <td className="whitespace-nowrap px-6 py-4 text-sm">
        <div>{media.mediaType}</div>
        <div>{media.productType}</div>
      </td>
      <td className="whitespace-nowrap px-6 py-4 text-sm">
        <div className="grid grid-cols-1 lg:grid-cols-2">
          <span>Likes: {media.likeCount}</span>
          <span>Comments: {media.commentsCount}</span>
        </div>
      </td>
    </tr>
  );

  function sendComment(externalMediaId: any, brandId: any, message: any) {
    if (isMentionedMedia) {
      return replyToMentionedMedia({
        variables: {
          externalMediaId: externalMediaId,
          brandId: brandId,
          facebookAccountId: facebookAccountId,
          message: message,
        },
      });
    } else {
      return commentOnMedia({
        variables: {
          externalMediaId: externalMediaId,
          brandId: brandId,
          facebookAccountId: facebookAccountId,
          message: message,
        },
      });
    }
  }
}
