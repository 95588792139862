import { Helmet } from "react-helmet";
import { useForm } from "react-hook-form";
import { Link } from "react-router-dom";

export type SignUpInputs = {
  name: string;
  email: string;
  password: string;
  confirmPassword: string;
  acceptTerms: boolean;
  companyTitle: string;
};

interface SignUpFormInterface {
  submitForm: (data: SignUpInputs) => Promise<void>;
}

export default function SignUpForm({ submitForm }: SignUpFormInterface) {
  const {
    register,
    handleSubmit,
    getValues,
    formState: { errors },
  } = useForm<SignUpInputs>();

  const submitSignUpForm = (data: SignUpInputs) => {
    submitForm(data);
  };

  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Kale | Sign up</title>
        <meta property="og:title" content="Kale | Sign up" />
        <meta property="og:description" content="Sign up to Kale" />
        <meta property="og:type" content="website" />
      </Helmet>
      <div className="flex min-h-full flex-col justify-center sm:px-6 lg:px-8">
        <div className="sm:mx-auto sm:w-full sm:max-w-md">
          <h2 className="mt-2 text-center text-2xl font-bold text-kale-green-500">
            Create your Kale account
          </h2>
        </div>

        <div className="mt-4 pb-1 sm:mx-auto sm:w-full sm:max-w-md">
          <div className="bg-white py-4 px-4 shadow sm:rounded-lg sm:px-10">
            <form
              className="space-y-4"
              action="#"
              method="POST"
              onSubmit={handleSubmit(submitSignUpForm)}
            >
              <div>
                <label
                  htmlFor="name"
                  className="block text-sm font-medium text-gray-700"
                >
                  Full Name
                </label>
                <div className="mt-1">
                  <input
                    id="name"
                    name="name"
                    type="name"
                    autoComplete="name"
                    {...register("name", { required: true })}
                    className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                  />
                </div>
              </div>

              <div>
                <label
                  htmlFor="email"
                  className="block text-sm font-medium text-gray-700"
                >
                  Email address
                </label>
                <div className="mt-1">
                  <input
                    id="email"
                    name="email"
                    type="email"
                    autoComplete="email"
                    {...register("email", { required: true })}
                    className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                  />
                </div>
              </div>

              <div>
                <label
                  htmlFor="password"
                  className="block text-sm font-medium text-gray-700"
                >
                  Password
                </label>
                <div className="mt-1">
                  <input
                    id="password"
                    name="password"
                    type="password"
                    {...register("password", {
                      required: true,
                      minLength: {
                        value: 8,
                        message: "Password must be at least 8 characters long.",
                      },
                    })}
                    className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                  />
                </div>
                {errors.password && (
                  <div className="pt-1 text-center text-sm font-bold text-red-700">
                    {errors.password.message}
                  </div>
                )}
              </div>

              <div>
                <label
                  htmlFor="password"
                  className="block text-sm font-medium text-gray-700"
                >
                  Confirm password
                </label>
                <div className="mt-1">
                  <input
                    id="confirmPassword"
                    name="confirmPassword"
                    type="password"
                    {...register("confirmPassword", { required: true })}
                    className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                  />
                </div>
                {errors.confirmPassword &&
                  errors.confirmPassword.type === "validate" && (
                    <div className="pt-1 text-center text-sm font-bold text-red-700">
                      Passwords don't match!
                    </div>
                  )}
              </div>

              <div>
                <label
                  htmlFor="companyTitle"
                  className="block text-sm font-medium text-gray-700"
                >
                  Current Title or Role
                </label>
                <div className="mt-1">
                  <input
                    id="companyTitle"
                    name="companyTitle"
                    type="companyTitle"
                    autoComplete="companyTitle"
                    {...register("companyTitle", { required: true })}
                    className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                  />
                </div>
              </div>

              {/* Accept terms of service box */}
              <div className="flex items-center">
                <input
                  id="acceptTerms"
                  name="acceptTerms"
                  type="checkbox"
                  {...register("acceptTerms", {
                    required: true,
                  })}
                  className="text-kale-green-500 focus:ring-kale-green-500"
                />

                <label
                  htmlFor="acceptTerms"
                  className="ml-2 block text-sm font-medium text-gray-700"
                >
                  By signing up you agree to our{" "}
                  <Link
                    className="hover:text-kale-orange-700 font-semibold text-kale-orange-500"
                    target="_blank"
                    to="/terms"
                  >
                    terms of service.
                  </Link>
                </label>
              </div>

              <div>
                <button
                  type="submit"
                  className="flex w-full justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                >
                  Create account
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}
