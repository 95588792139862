import EmptyDataState from '../EmptyDataState';
import PendingChallengeRow from './PendingChallengeRow';
import { Challenge } from '@kalecard/common';

export default function PendingChallengesTable({
  pendingChallenges,
}: {
  pendingChallenges: Challenge[];
}) {
  return (
    <>
      <div className="flex-cols flex w-full">
        <div className="w-full">
          <div className="lign-middle inline-block w-full">
            <div className="overflow-hidden">
              {pendingChallenges?.length > 0 && (
                <table className="w-full divide-y divide-gray-200">
                  <thead>
                    <tr>
                      <th
                        scope="col"
                        className="py-4 pr-6 text-left text-sm font-semibold text-gray-900"
                      >
                        Prompt
                      </th>
                      <th
                        scope="col"
                        className="px-6 py-4 text-center text-sm font-semibold text-gray-900"
                      >
                        Created on
                      </th>
                      <th className="px-6 py-4"></th>
                      <th className="px-6 py-4"></th>
                      <th className="px-6 py-4"></th>
                    </tr>
                  </thead>
                  <tbody className="divide-y divide-gray-200 bg-white">
                    {pendingChallenges?.map((challenge) => {
                      return (
                        <PendingChallengeRow
                          key={challenge.id}
                          challenge={challenge}
                        />
                      );
                    })}
                  </tbody>
                </table>
              )}
              {pendingChallenges?.length == 0 && (
                <EmptyDataState message="There are no new challenges waiting to be approved." />
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
