import { Loading } from '@kalecard/common';
import { useContext, useState } from 'react';
import { useForm } from 'react-hook-form';
import { UserContext } from '../../providers/UserProvider';
import { Subheading } from '../catalyst/heading';

const items = [
  { id: 1, name: 'Instagram DM', code: 'igdm' },
  { id: 2, name: 'Email Campaign', code: 'marketing_email' },
  { id: 3, name: 'TikTok', code: 'tiktok' },
  // More items...
];

function getUrl(referralCode: string, code: string): string {
  const url = `https://www.kalecard.com/t/${referralCode}?ref=${referralCode}_${code}`;
  return url;
}

export default function ReferralLinkGenerator() {
  const { brandRecord } = useContext(UserContext);

  const { register, handleSubmit, watch } = useForm({
    defaultValues: {
      referral_code: 'newsletter email',
    },
  });
  const watchReferralCode = watch('referral_code');

  const onSubmit = (data) => {
    console.log(data);
  };

  if (brandRecord === null || brandRecord === undefined) {
    return <Loading />;
  }

  if (
    brandRecord?.referralCode === undefined ||
    brandRecord?.referralCode === null
  ) {
    return null;
  }

  return (
    <div className="space-y-6">
      <div>
        <Subheading>Landing Page Links</Subheading>
        <p className="text-sm text-gray-500">
          Invite your creators with unique links to track what channel they sign
          up through.
        </p>
      </div>
      <div>
        <Subheading>Examples:</Subheading>
        <ul
          role="list"
          className="divide-y divide-gray-200"
        >
          {items.map((item) => (
            <li
              key={item.id}
              className="flex py-4"
            >
              <div>
                <p className="text-sm font-medium text-gray-900">
                  {item.name}:
                </p>
                <ReferralLink
                  brandCode={brandRecord.referralCode}
                  refCode={item.code}
                />
              </div>
            </li>
          ))}
          <li className="flex py-4">
            <div>
              <p className="text-sm font-medium text-gray-900">
                Enter your marketing channel below to generate a link:
              </p>
              {/* <p className="text-sm text-gray-500">{getUrl(brandRecord.referralCode, item.code)}</p> */}
              <form
                onSubmit={handleSubmit(onSubmit)}
                className="mb-4 flex flex-col"
              >
                <div className="mb-1 mt-1">
                  <input
                    {...register('referral_code')}
                    type="text"
                    name="referral_code"
                    id="referral_code"
                    className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                    placeholder="newsletter email"
                  />
                </div>
                <ReferralLink
                  brandCode={brandRecord.referralCode}
                  refCode={watchReferralCode}
                />
              </form>
            </div>
          </li>
        </ul>
      </div>
    </div>
  );
}

function ReferralLink({ brandCode, refCode }) {
  const [copied, setCopied] = useState<boolean>(false);
  const code = refCode.replace(/\s+/g, '_');
  const url = getUrl(brandCode, code);

  const handleClick = () => {
    navigator.clipboard.writeText(url);
    setCopied(true);
  };

  return (
    <div className="flex space-x-2 text-sm">
      <p className="text-kale-green-400">{url}</p>
      <button
        className="font-bold text-kale-green-500"
        onClick={handleClick}
      >
        Copy
      </button>
      {copied && <span>Copied code to clipboard!</span>}
    </div>
  );
}
