import { useMutation } from "@apollo/client";
import { MediaUrl, Post, Spinner, prettyDecimal } from "@kalecard/common";
import { useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { CONTENT_WHITELIST_REQUEST } from "../../graphql/mutations";
import { WHITELIST_CONTENT } from "../../graphql/queries";
import MediaComponent from "../MediaComponent";
import { useHistory } from "react-router";

export type ContentWhitelistRequestFormInputs = {
  budget: number;
};

interface ContentWhitelistRequestFormProps {
  post: Post;
  mediaUrl: MediaUrl;
  brandId: string;
  onSubmit?: () => void;
}

const MINIMUM_AD_BUDGET = 500;

export default function ContentWhitelistRequest(
  props: ContentWhitelistRequestFormProps
) {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const history = useHistory();
  const { reset, register, handleSubmit } =
    useForm<ContentWhitelistRequestFormInputs>({
      defaultValues: {
        budget: 500,
      },
    });
  const [contentWhitelistRequest] = useMutation(CONTENT_WHITELIST_REQUEST, {
    refetchQueries: [WHITELIST_CONTENT],
  });

  const submitContentWhitelistRequestForm = (
    data: ContentWhitelistRequestFormInputs
  ) => {
    if (data.budget < MINIMUM_AD_BUDGET) {
      setError(
        `Whitelisting amount must be greater than $${prettyDecimal(
          MINIMUM_AD_BUDGET
        )}.`
      );
    } else {
      setError(null);
      submitForm(data);
      props.onSubmit();
    }
  };

  const submitForm: SubmitHandler<ContentWhitelistRequestFormInputs> = async (
    data
  ) => {
    setIsLoading(true);
    console.log(data);
    const variables = {
      postId: props.post.id,
      childId: props.mediaUrl.carouselItem?.id,
      brandId: props.brandId,
      budget: data.budget,
    };
    try {
      const result = await contentWhitelistRequest({ variables });
      console.log(result);
      if (result.data.contentWhitelistRequest == true) {
        setError(null);
        reset();
        history.push("/whitelisting/processing");
      } else {
        // Set an error
        console.error("Error creating new content whitelist request.");
        setError(
          "Something went wrong, try refreshing this page or try again later."
        );
      }
    } catch (err) {
      console.error(err);
      setError("Something went wrong, please try again later.");
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <form>
        <div>
          <p className="text-black-400">
            Whitelisting is the process of converting your creator's content
            into an advertisement. The ad will run from the creator's handle.
          </p>
          <a
            className="text-sm font-bold text-kale-orange-500 underline hover:text-kale-orange-400"
            target="_blank"
            href="https://kalecard.notion.site/Whitelisting-with-Kale-df776935e4e94618afc7fa6f0f06d32a"
          >
            Learn more about whitelisting with Kale.
          </a>
        </div>
        <div className="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6 md:h-56 ">
          <div className="sm:col-span-3">
            <div>
              <label
                htmlFor="budget"
                className="block font-medium text-gray-700"
              >
                <p>
                  Budget{" "}
                  <span className="font-bold">(Minimum Ad Spend: $500)</span>
                </p>
              </label>
              <div className="relative mt-1 rounded-md shadow-sm">
                <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                  <span className="text-gray-500 sm:text-sm">$</span>
                </div>
                <input
                  type="number"
                  onWheel={(e) => (e.target as HTMLElement).blur()}
                  step="1"
                  name="budget"
                  id="budget"
                  min="0.01"
                  className="block w-full rounded-md border-gray-300 pl-7 pr-12 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                  {...register("budget", { required: true })}
                />
                <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
                  <span
                    className="text-gray-500 sm:text-sm"
                    id="price-currency"
                  >
                    USD
                  </span>
                </div>
              </div>
              <div className="flex justify-center pt-5">
                <div className="grid">
                  <button
                    type="button"
                    onClick={handleSubmit(submitContentWhitelistRequestForm)}
                    disabled={isLoading}
                    className="max-w-20 place-self-center rounded-md border border-transparent bg-kale-mint-500 py-2 px-4 text-sm font-medium text-gray-900 shadow-sm hover:bg-green-600 focus:outline-none focus:ring-2 focus:ring-kale-green-500 focus:ring-offset-2"
                  >
                    Submit Content Whitelist Request
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="h-64 -translate-y-14 scale-50 sm:col-span-3 md:h-40">
            <MediaComponent
              url={props.mediaUrl.url}
              mediaType={props.mediaUrl.mediaType}
              showControls={false}
            />
          </div>
        </div>
        <div className="mt-2 flex justify-end">
          <p className="font-bold text-red-500">{error}</p>
        </div>
        {isLoading && (
          <div className="flex flex-wrap content-center justify-center">
            <Spinner size="h-6 w-6" />
          </div>
        )}
      </form>
    </>
  );
}
