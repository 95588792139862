import { NavLink, Route, Switch } from "react-router-dom";
import GeneralSettingsPage from "../components/settings/GeneralSettingsPage";
import InstagramSettingsPage from "../components/settings/instagram/InstagramSettingsPage";
import TeamSettingsPage from "../components/settings/team/TeamSettingsPage";
import LandingPageSettingsPage from "../components/settings/LandingPageSettingsPage";

const tabs = [
  { name: "General", href: `/settings/general`, current: true },
  { name: "Instagram", href: `/settings/instagram`, current: false },
  { name: "Team", href: `/settings/team`, current: false },
  { name: "Your Landing Page", href: `/settings/landingPage`, current: false },
];

export default function SettingsPage() {
  return (
    <>
      <div className="flex max-w-7xl justify-between px-4 pb-2 sm:px-6 md:px-8">
        <h1 className="text-2xl font-semibold text-gray-900">Settings</h1>
      </div>
      <div className="border-b border-gray-200 px-4 pt-2 sm:px-6 md:px-8">
        <div className="block">
          <nav className="-mb-px flex space-x-8">
            {tabs.map((tab) => (
              <NavLink
                key={tab.name}
                to={tab.href}
                className={(isActive) =>
                  (isActive
                    ? "border-b-2 border-black text-black "
                    : "text-gray-500 hover:border-b-2 hover:border-gray-500 hover:text-gray-600") +
                  "whitespace-nowrap px-1 pb-4 text-sm font-medium"
                }
                aria-current={tab.current ? "page" : undefined}
              >
                {tab.name}
              </NavLink>
            ))}
          </nav>
        </div>
      </div>
      <div>
        <Switch>
          <Route path="/settings/general">
            <GeneralSettingsPage />
          </Route>
          <Route exact path="/settings/instagram">
            <InstagramSettingsPage />
          </Route>
          <Route exact path="/settings/team">
            <TeamSettingsPage />
          </Route>
          <Route exact path="/settings/landingPage">
            <LandingPageSettingsPage />
          </Route>
          <Route exact path="/settings">
            <GeneralSettingsPage />
          </Route>
        </Switch>
      </div>
    </>
  );
}
