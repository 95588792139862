import { SubmitHandler, useForm } from "react-hook-form";
import { COMPLETE_BRAND_USER_DATA } from "../../graphql/mutations";
import { useMutation } from "@apollo/client";
import { UserContext } from "../../providers/UserProvider";
import { useContext, useState } from "react";
import { Spinner } from "@kalecard/common";

interface CompleteProfileDataInputs {
  companyTitle: string;
}

export default function CompleteProfileDataForm({ onSubmit } : { onSubmit: () => void; }) {
  const { brandRecord } = useContext(UserContext);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [completeBrandUserData] = useMutation(COMPLETE_BRAND_USER_DATA);
  const { reset, register, handleSubmit } = useForm<CompleteProfileDataInputs>();

  const submit: SubmitHandler<CompleteProfileDataInputs> = async (
    data: CompleteProfileDataInputs
  ) => {
    setIsLoading(true);
    try {
      await completeBrandUserData({
        variables: {
          companyTitle: data.companyTitle,
        },
      });
      reset();
      onSubmit();
    } catch (err) {
      console.log(err);
      setError("Something went wrong. Please try again.");
    }
    setIsLoading(false);
  };

  const brandName = brandRecord?.name ? ` at ${brandRecord.name}.` : "." 

  return (
    <form className="flex flex-col space-y-4 mx-10 my-2" onSubmit={handleSubmit(submit)}>
      <p className="text-sm">Please enter your current title or role{brandName}</p>
      <div className="flex space-x-4">
      <input
        placeholder="e.g. Social Media Manager"
        id="name"
        type="text"
        {...register("companyTitle", { required: true })}
        className="border-kale-green-500 focus:border-kale-orange-500 focus:ring-kale-orange-500 block flex-1 rounded-md text-sm"
      />
      <button
        type="submit"
        disabled={isLoading}
        className="w-fit px-6 inline-flex items-center justify-center rounded-md border border-transparent bg-green-900 py-2 text-sm font-medium text-white shadow-sm hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-green-700 focus:ring-offset-2"
      >
        Submit
      </button>
      </div>
      {isLoading && (
        <div className="flex justify-center">
          <Spinner size="h-6 w-6" />
        </div>
      )}
      {error && (
        <div className="font-medium text-sm text-red-500">{error}</div>
      )}
    </form>
  );
}