import { useEffect, useState } from "react";
import { Loading } from "@kalecard/common";
import { CheckBadgeIcon } from "@heroicons/react/24/solid";
import { useMutation, useQuery } from "@apollo/client";
import { GET_NEXT_BONSAI_TASK } from "../../../graphql/queries";
import { COMPLETE_BONSAI_TASK } from "../../../graphql/mutations";
import { StandardReviewPanel, VideoUploadReviewPanel } from "./ReviewPanels";
import { BrandReviewOutput } from "../BonsaiModels";

export default function BrandReview({ queueName }: { queueName: string }) {
  const { data, loading, refetch } = useQuery(GET_NEXT_BONSAI_TASK, {
    variables: { queueName: queueName },
  });
  const [completeTask] = useMutation(COMPLETE_BONSAI_TASK);
  const [remainingTasks, setRemainingTasks] = useState(0);
  const isVideoUploadReview = queueName.includes("video_upload");

  useEffect(() => {
    setRemainingTasks(data?.getNextTask?.remainingTasksInQueue ?? 0);
  }, [data]);

  const onSubmit = async (outcome: BrandReviewOutput) => {
    const outcomeString = JSON.stringify(outcome);
    await completeTask({
      variables: {
        queueName: queueName,
        taskId: data.getNextTask.taskId,
        outcome: outcomeString,
      },
    });

    await refetch();
  };

  return (
    <div className="mt-6 pt-4">
      {loading ? (
        <Loading />
      ) : (
        <>
          {data.getNextTask ? (
            isVideoUploadReview ? (
              <VideoUploadReviewPanel
                metadata={JSON.parse(data.getNextTask.metadata)}
                onComplete={(outcome) => onSubmit(outcome)}
                videosRemaining={remainingTasks}
              />
            ) : (
              <StandardReviewPanel
                metadata={JSON.parse(data.getNextTask.metadata)}
                onComplete={(outcome) => onSubmit(outcome)}
                videosRemaining={remainingTasks}
              />
            )
          ) : (
            <div className="flex h-screen items-center justify-center">
              <EmptyState />
            </div>
          )}
        </>
      )}
    </div>
  );
}

const EmptyState = () => {
  return (
    <div className="-mt-96 text-center">
      <div className={"flex justify-center"}>
        <CheckBadgeIcon className={"h-20 w-20 text-green-500"} />
      </div>
      <h3 className="text-md mt-2 font-semibold text-gray-900">
        All Videos Reviewed!
      </h3>
      <p className="text-md mt-1 text-gray-500">
        No more videos left in the queue. Please check back later.
      </p>
    </div>
  );
};
