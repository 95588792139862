import { Brand } from "@kalecard/common";
import { useApolloClient } from "@apollo/client";
import { INITIATE_CONTENT_BUDGET_CHECKOUT } from "../../../graphql/queries";
import {
  InitiateContentBudgetCheckoutQuery,
  InitiateContentBudgetCheckoutQueryVariables,
} from "../../../__generated__/graphql";
import {
  EmbeddedCheckout,
  EmbeddedCheckoutProvider,
} from "@stripe/react-stripe-js";
import { useCallback } from "react";
import { loadStripe } from "@stripe/stripe-js";
import { publishableKey } from "../../../utils/stripe";

const stripePromise = loadStripe(publishableKey);

export default function ContentBudgetSettings({
  brand,
  updateBrandRecord,
}: {
  brand: Brand;
  updateBrandRecord: (brandId: string) => void;
}) {
  const client = useApolloClient();

  const fetchClientSecret = useCallback((): Promise<string> => {
    return client
      .query<
        InitiateContentBudgetCheckoutQuery,
        InitiateContentBudgetCheckoutQueryVariables
      >({
        query: INITIATE_CONTENT_BUDGET_CHECKOUT,
        variables: { brandId: brand.id },
        fetchPolicy: "no-cache",
      })
      .then((response) => {
        return response.data.initiateContentBudgetCheckout.clientSecret;
      })
      .catch((error) => {
        console.error("Error fetching checkout session:", error);
        throw error;
      });
  }, [client, brand?.id]);

  const options = { fetchClientSecret };

  const onCheckoutComplete = () => {
    console.log("Checkout complete");
    setTimeout(() => {
      updateBrandRecord(brand.id);
    }, 1000);
  };

  return (
    <EmbeddedCheckoutProvider
      stripe={stripePromise}
      options={{ ...options, onComplete: onCheckoutComplete }}
    >
      <EmbeddedCheckout className="m-4 w-full" />
    </EmbeddedCheckoutProvider>
  );
}
