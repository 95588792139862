import { useContext, useEffect, useState } from "react";
import { UserContext } from "../providers/UserProvider";
import { useLazyQuery } from "@apollo/client";
import {
  BrandOrdersDataQuery,
  BRAND_ORDERS,
  BRAND_ORDER_METRICS,
} from "../graphql/queries";
import {
  prettyDecimal,
  TiktokLogo,
  InstagramLogo,
  Loading,
} from "@kalecard/common";
import Table from "../components/utils/Table";
import Dropdown from "../components/utils/Dropdown";
import {
  generateDateRangeOptions,
  generateLastQuarterAndLastYearOptions,
} from "../utils/time";

const summaryCardItems = [
  {
    key: "kaleOwesAmount",
    label: "Gift Cards Issued by Kale",
    formatter: (val) => val && `$${prettyDecimal(val / 100)}`,
  },
  {
    key: "brandOwesAmount",
    label: "Transaction Fees",
    formatter: (val) => val && `$${prettyDecimal(val / 100)}`,
  },
  {
    key: "totalSpend",
    label: "Orders Total",
    formatter: (val) => val && `$${prettyDecimal(val / 100)}`,
  },
  {
    key: "numOrders",
    label: "Number of Orders",
  },
];

const presetOptions = generateLastQuarterAndLastYearOptions();
const dateRangeOptions = generateDateRangeOptions();

const OrdersPage = () => {
  const { brandId, brandRecord } = useContext(UserContext);
  const [selectedDateObj, setSelectedDateObj] = useState(
    dateRangeOptions[0].value
  );
  const [orderEdges, setOrderEdges] = useState([]);
  const [showLoadMore, setShowLoadMore] = useState(true);

  const [getOrderMetrics, { data: orderMetricsData }] = useLazyQuery(
    BRAND_ORDER_METRICS,
    {
      variables: {
        brandId,
        after: selectedDateObj.endTime,
        before: selectedDateObj.startTime,
      },
      fetchPolicy: "network-only",
    }
  );

  const [getOrders, { loading: loadingOrders }] =
    useLazyQuery<BrandOrdersDataQuery>(BRAND_ORDERS, {
      fetchPolicy: "network-only",
    });

  const loadOrders = async (overwrite = false) => {
    if (!loadingOrders) {
      const data = await getOrders({
        variables: {
          brandId,
          first: 20,
          after: overwrite
            ? selectedDateObj.endTime
            : orderEdges[orderEdges.length - 1]?.cursor,
          before: selectedDateObj.startTime,
        },
      });
      const fetchedOrderEdges = data?.data?.brandOrders?.edges;
      if (overwrite) {
        setOrderEdges(fetchedOrderEdges);
      } else {
        setOrderEdges([...orderEdges, ...fetchedOrderEdges]);
      }

      if (!data?.data?.brandOrders?.pageInfo?.hasNextPage) {
        setShowLoadMore(false);
      }
    }
  };

  useEffect(() => {
    setOrderEdges([]);
    setShowLoadMore(true);
    getOrderMetrics();
    loadOrders(true);
  }, [selectedDateObj]);

  const shopifyStoreName = brandRecord?.shopifyDomain?.split(".")?.[0];
  const shopifyOrderURLPrefix = `https://admin.shopify.com/store/${shopifyStoreName}/orders/`;

  const tableData = orderEdges.map((orderEdge) => {
    const {
      createdAt,
      externalOrderId,
      kaleOwesAmount,
      totalLineItemsPriceAmount,
      creatorTransactions,
      creatorPendingTransactions,
      shopifyDiscountCode,
      brandOwesAmount,
    } = orderEdge.node;

    const externalOrderIdSplit = externalOrderId?.split("/");
    const orderId = externalOrderIdSplit[externalOrderIdSplit.length - 1];

    const transactions = creatorTransactions.length
      ? creatorTransactions
      : creatorPendingTransactions;

    const orderType = () => {
      switch (transactions?.[0]?.type) {
        case "DISCOUNT_CODE_COMMISSION":
          return (
            <div className="w-full rounded-full bg-kale-green-500 py-0.5 text-center text-white">
              Referral Code
            </div>
          );
        case "OFFER_CASHBACK":
          return (
            <div className="w-full rounded-full bg-kale-mint-500 py-0.5 text-center">
              Gift Card
            </div>
          );
        default:
          return (
            <div className="w-full rounded-full bg-kale-green-500 py-0.5 text-center text-white">
              Product Gift
            </div>
          );
      }
    };

    const externalUsernames = [];
    const addExternalUsers = (externalUsers) => {
      externalUsers?.forEach((externalUser) => {
        externalUsernames.push(
          <div className="flex flex-row items-center space-x-2">
            <span>{externalUser.username}</span>
            {externalUser.externalPlatform === "INSTAGRAM" ? (
              <InstagramLogo />
            ) : (
              <TiktokLogo />
            )}
          </div>
        );
      });
    };

    let brandOwes = 0;
    transactions?.forEach((transaction) => {
      addExternalUsers(transaction.creator?.externalUsers);
      brandOwes += transaction.amount;
    });

    if (externalUsernames.length === 0) {
      addExternalUsers(shopifyDiscountCode?.creator?.externalUsers);
    }

    const transactionFee = () => {
      switch (transactions?.[0]?.type) {
        case "DISCOUNT_CODE_COMMISSION":
        case "OFFER_CASHBACK":
          return `$${prettyDecimal(brandOwesAmount)}`;
        default:
          return "-";
      }
    };

    const dateProcessed = () => {
      switch (transactions?.[0]?.type) {
        case "DISCOUNT_CODE_COMMISSION":
        case "OFFER_CASHBACK":
          const processedAt = creatorTransactions.length
            ? creatorTransactions?.[0]?.createdAt
            : null;
          return processedAt ? (
            new Date(parseInt(processedAt)).toLocaleDateString("en-US")
          ) : (
            <div className="w-full rounded-full bg-kale-orange-400 px-2 py-0.5 text-center">
              Pending
            </div>
          );
        default:
          return new Date(parseInt(createdAt)).toLocaleDateString("en-US");
      }
    };

    return {
      "Order ID": (
        <a
          href={`${shopifyOrderURLPrefix}${orderId}`}
          target="_blank"
          rel="noreferrer"
          className="underline"
        >
          {orderId}
        </a>
      ),
      "Order Type": orderType(),
      Username: <div className="flex flex-col">{externalUsernames}</div>,
      "Order Total": `$${prettyDecimal(totalLineItemsPriceAmount / 100)}`,
      "Gift Card Value": kaleOwesAmount
        ? `$${prettyDecimal(kaleOwesAmount / 100)}`
        : "-",
      "Transaction Fee": transactionFee(),
      "Date Purchased": new Date(parseInt(createdAt)).toLocaleDateString(
        "en-US"
      ),
      "Date Processed": dateProcessed(),
    };
  });

  const summaryCardComponents = summaryCardItems.map((summaryCardItem) => {
    const val = orderMetricsData?.brandOrderMetrics?.[summaryCardItem.key];
    return (
      <div className="flex w-60 flex-col space-y-2 rounded-lg border border-gray-200 py-4 px-6 shadow-md">
        <div className="text-gray-500">{summaryCardItem.label}</div>
        <div className="text-2xl font-normal">
          {summaryCardItem.formatter ? summaryCardItem.formatter(val) : val}
        </div>
      </div>
    );
  });

  return (
    <div className="flex h-full flex-col overflow-auto px-5 pb-6">
      <h1 className="mb-6 text-2xl font-semibold text-black">Orders</h1>
      <Dropdown
        presetItems={presetOptions}
        items={dateRangeOptions}
        selectedValue={selectedDateObj}
        setSelectedValue={setSelectedDateObj}
      />
      <div className="my-6 flex flex-row space-x-6">
        {summaryCardComponents}
      </div>
      <Table data={tableData} />
      {!loadingOrders && showLoadMore && (
        <button
          className="mt-4 rounded-lg border border-green-700 bg-transparent py-2 px-6 text-center font-semibold text-green-700 hover:border-transparent hover:bg-kale-mint-500"
          onClick={() => loadOrders(false)}
        >
          Load more
        </button>
      )}
      {loadingOrders && <Loading />}
    </div>
  );
};

export default OrdersPage;
