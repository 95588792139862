import { createContext, useContext, useEffect, useState } from 'react';
import { Badges, BRAND_BADGES } from '../graphql/queries';
import { useQuery } from '@apollo/client';
import { UserContext } from './UserProvider';
import apolloClient from '../config/apolloClient';

export const BadgesContext = createContext<{
  badges: Badges;
  setBadges: React.Dispatch<React.SetStateAction<Badges>>;
  refreshBadges: () => void;
}>(null);

export const BadgesDispatchContext = createContext(null);

const BadgesProvider = ({ children }: any) => {
  const [badges, setBadges] = useState<Badges>(null);
  const { brandId } = useContext(UserContext);

  const { data } = useQuery(BRAND_BADGES, {
    variables: {
      brandId,
    },
    onCompleted: (data) => {
      setBadges(data.brandBadges);
    },
    fetchPolicy: 'network-only',
  });

  const refreshBadges = async () => {
    const result = await apolloClient.query({
      query: BRAND_BADGES,
      variables: {
        brandId,
      },
      fetchPolicy: 'network-only',
    });
    setBadges(result.data.brandBadges);
  };

  return (
    <BadgesContext.Provider value={{ badges, setBadges, refreshBadges }}>
      {children}
    </BadgesContext.Provider>
  );
};

export default BadgesProvider;
