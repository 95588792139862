import { useState } from "react";
import { XMarkIcon, CheckIcon } from "@heroicons/react/24/solid";
import { Edge, Modal, WhitelistContent } from "@kalecard/common";
import ContentWhitelistRequestFormV2 from "../../forms/ContentWhitelistRequestFormV2";
import ContentWhitelistRejectForm from "../../forms/ContentWhitelistRejectForm";
import MediaComponent from "../../MediaComponent";
import PostCaptionStats from "../../utils/PostCaptionStats";

interface WhitelistContentListComponentInterface {
  whitelistContent: Edge<WhitelistContent>;
  showActionButtons: boolean;
}

export default function WhitelistContentListComponent(
  props: WhitelistContentListComponentInterface
) {
  const [
    contentWhitelistRequestModalOpen,
    setContentWhitelistRequestModalOpen,
  ] = useState(false);
  const [contentWhitelistRejectModalOpen, setContentWhitelistRejectModalOpen] =
    useState(false);

  const whitelistContent = props.whitelistContent.node;
  const post = whitelistContent!.post!;
  const externalUser = post.externalUser!;
  const mediaUrl = post?.mediaUrls[0];

  return (
    <li key={whitelistContent.id} className="inline-block">
      <div className="m-2 rounded-lg bg-white text-center shadow">
        <div className="flex flex-col flex-col items-center justify-center space-y-2 p-2">
          <MediaComponent
            url={mediaUrl?.url || ""}
            mediaType={mediaUrl?.mediaType || "VIDEO"}
            shouldChangeColor={true}
          />
          <PostCaptionStats post={post} hideIG={true} />
          {props.showActionButtons && (
            <div className="mt-2 flex h-8 w-full space-x-2">
              <button
                onClick={() => setContentWhitelistRejectModalOpen(true)}
                className="flex flex-1 items-center justify-center rounded-md border border-red-500 text-red-500 hover:bg-red-500 hover:text-white"
              >
                <XMarkIcon className="h-5 w-5" />
              </button>
              <button
                onClick={() => setContentWhitelistRequestModalOpen(true)}
                className="flex flex-1 items-center justify-center rounded-md border border-green-500 text-green-500 hover:bg-green-500 hover:text-white"
              >
                <CheckIcon className="h-5 w-5" />
              </button>
            </div>
          )}
        </div>
        <Modal
          title="Whitelist Content Request"
          isModalOpen={contentWhitelistRequestModalOpen}
          onClose={() => setContentWhitelistRequestModalOpen(false)}
          className="bg-white"
        >
          <ContentWhitelistRequestFormV2
            whitelistContent={whitelistContent}
            closeModal={() => setContentWhitelistRejectModalOpen(false)}
          />
        </Modal>
        <Modal
          title="What Was This Post Missing?"
          isModalOpen={contentWhitelistRejectModalOpen}
          onClose={() => setContentWhitelistRejectModalOpen(false)}
          className="bg-white"
        >
          <ContentWhitelistRejectForm
            whitelistContent={whitelistContent}
            closeModal={() => setContentWhitelistRejectModalOpen(false)}
          />
        </Modal>
      </div>
    </li>
  );
}
