import { useQuery } from "@apollo/client";
import { Spinner } from "@kalecard/common";
import { useContext, useEffect, useState } from "react";
import { useInView } from "react-intersection-observer";
import { useParams } from "react-router";
import InstagramMediaComponent from "../components/settings/instagram/InstagramMediaComponent";
import { BRAND_INSTAGRAM_MEDIA } from "../graphql/queries";
import { UserContext } from "../providers/UserProvider";

interface NextPage {
  newLength: number;
  hasNextPage: boolean;
  after: string;
}

export default function InstagramMediaPage() {
  const { ref, inView } = useInView({ threshold: 1 });
  const params = useParams();
  const facebookAccountId = params["id"];
  const { user, brandId } = useContext(UserContext);
  const [edges, setEdges] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [enableCommentOnMedia, _] = useState(
    false // user.uid === "Fvx1q2IZBWYb2aUTTRp9I6yrzm02"
  );
  const [nextPageData, setNextPageData] = useState<NextPage>(null);

  const setMedia = (data: any) => {
    const newEdges = [...edges, ...data.brandInstagramMedia.edges];
    setNextPageData({
      newLength: newEdges.length,
      hasNextPage: data.brandInstagramMedia.pageInfo.hasNextPage,
      after:
        data.brandInstagramMedia.edges[
          data.brandInstagramMedia.edges.length - 1
        ]?.cursor,
    });
    setEdges([...edges, ...data.brandInstagramMedia.edges]);
  };

  const { loading, fetchMore } = useQuery(BRAND_INSTAGRAM_MEDIA, {
    variables: {
      first: 20,
      brandId: brandId,
      facebookAccountId: facebookAccountId,
    },
    onCompleted: (data) => setMedia(data),
  });

  const loadMore = () => {
    setIsLoading(true);
    fetchMore({
      variables: {
        after: nextPageData.after,
      },
    }).then((fetchMoreResult) => {
      // Update variables.limit for the original query to include
      // the newly added feed items.
      setMedia(fetchMoreResult.data);
      setIsLoading(false);
    });
  };

  useEffect(() => {
    if (
      inView === true &&
      nextPageData?.hasNextPage === true &&
      nextPageData?.newLength === edges.length
    ) {
      loadMore();
    }
  }, [inView, nextPageData, edges]);

  return (
    <>
      <div className="mx-auto max-w-7xl px-4 pb-2 sm:px-6 md:px-8">
        <h1 className="text-2xl font-semibold text-gray-900">
          Instagram Media
        </h1>
      </div>
      <div className="mx-auto max-w-7xl px-4 sm:px-6 md:px-8">
        <div className="mb-4 flex flex-col items-center">
          <div className="mb-2 w-full overflow-x-auto">
            <div className="inline-block min-w-full align-middle">
              <div className="overflow-hidden border border-gray-200 shadow sm:rounded-lg">
                <table className="min-w-full table-auto divide-y divide-gray-200">
                  <thead className="bg-gray-50">
                    <tr>
                      <th
                        scope="col"
                        className="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                      >
                        Caption
                      </th>
                      {enableCommentOnMedia && (
                        <th
                          scope="col"
                          className="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                        >
                          Post Comment
                        </th>
                      )}
                      <th
                        scope="col"
                        className="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                      >
                        Type
                      </th>
                      <th
                        scope="col"
                        className="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                      >
                        Insights
                      </th>
                    </tr>
                  </thead>
                  <tbody className="divide-y divide-gray-200 bg-white">
                    {edges.map((edge) => (
                      <InstagramMediaComponent
                        key={edge.node.id}
                        media={edge.node}
                        brandId={brandId}
                        facebookAccountId={facebookAccountId}
                        enableCommentOnMedia={enableCommentOnMedia}
                      />
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div ref={ref}>
            {(isLoading || loading) && (
              <div className="flex flex-wrap content-center">
                <Spinner size="h-6 w-6" />
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
}
