import { BrandUser } from "@kalecard/common";
import BrandAdminRow from "./BrandAdminRow";
import { useContext } from "react";
import { UserContext } from "../../../providers/UserProvider";

export default function BrandAdminsTable({ admins, isBrandPOC, removeAdmin } : { admins: BrandUser[], isBrandPOC: boolean, removeAdmin?: (admin: BrandUser) => void }) {
  const { userRecord } = useContext(UserContext);

  return (
    <div className="mt-1 flex flex-col">
        <div className="-my-2 -mx-4 overflow-y-visible sm:-mx-6 lg:-mx-8">
          <div className="inline-block py-2 w-full align-middle md:px-6 lg:px-8">
            <div className="shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
              {admins.length > 0 && (
              <table className="divide-y divide-gray-300 w-full ">
                <thead className="bg-gray-50">
                  <tr>
                    <th
                      scope="col"
                      className="py-4 text-left text-sm font-semibold text-gray-900 px-4"
                    >
                      Email
                    </th>
                    <th
                      scope="col"
                      className="py-4 text-left text-sm font-semibold text-gray-900 px-4"
                    >
                      Date Added
                    </th>
                    {isBrandPOC && (
                      <th
                      scope="col"
                      className="py-4 text-left text-sm font-semibold text-gray-900 px-4"
                      >
                      </th>
                    )}
                  </tr>
                </thead>
                <tbody className="divide-y divide-gray-200 bg-white">
                  {admins.map((admin) => {
                    const isLoggedInAdmin = userRecord?.id === admin.id;
                    return (
                      <BrandAdminRow
                        key={admin.id}
                        admin={admin}
                        isBrandPOC={isBrandPOC}
                        isLoggedInAdmin={isLoggedInAdmin}
                        removeAdmin={removeAdmin}
                      />
                    );
                  })}
                </tbody>
              </table>
              )}
            </div>
          </div>
        </div>
      </div>
  );
}