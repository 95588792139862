import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyD2hbS8P6m2KY_tQu37EtRECgel1--rBkk",
  authDomain: "kale-brands.firebaseapp.com",
  projectId: "kale-brands",
  storageBucket: "kale-brands.appspot.com",
  messagingSenderId: "516870299651",
  appId: "1:516870299651:web:df88062463072351daee36",
  measurementId: "G-KGD7H1DPR8",
};

firebase.initializeApp(firebaseConfig);

export const auth = firebase.auth();
export const firestore = firebase.firestore();
