import { Connection, FacebookAccount } from "@kalecard/common";
import FacebookAccountItem from "./FacebookAccountItem";

export default function FacebookAccounts({
  facebookAccounts,
  rawFacebookAccounts,
}: {
  facebookAccounts: Connection<FacebookAccount>;
  rawFacebookAccounts: FacebookAccount[];
}) {
  const facebookAccountNames = new Set(
    facebookAccounts?.edges.map((item) => item.node.name)
  );

  return (
    <>
      {(facebookAccounts?.edges?.length > 0 ||
        rawFacebookAccounts.length > 0) && (
        <div className="pt-2">
          <h3 className="pb-1 text-sm font-medium text-gray-700">
            Connected Accounts:
          </h3>
          <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
            <ul
              role="list"
              className="divide-y divide-gray-200 rounded-md border border-gray-200"
            >
              {facebookAccounts?.edges.map((edge) => {
                return (
                  <FacebookAccountItem
                    key={edge.node.id}
                    facebookAccount={edge.node}
                  />
                );
              })}
              {rawFacebookAccounts
                .filter((facebookAccount) => {
                  return !facebookAccountNames.has(facebookAccount.name);
                })
                .map((facebookAccount) => {
                  return (
                    <FacebookAccountItem
                      key={facebookAccount.id}
                      facebookAccount={facebookAccount}
                      isNewAccount={true}
                    />
                  );
                })}
            </ul>
          </dd>
        </div>
      )}
    </>
  );
}
