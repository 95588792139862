type BadgeProps = {
  number?: number;
  bgColor?: string;
}

const Badge = ({ number, bgColor }: BadgeProps) => {
  if (!number) return null; 
  const content = number > 9 ? "9+" : number;
  return (
    <div className={`${bgColor || ''} rounded-xl px-2 py-0.5 text-xs text-white`}>{content}</div>
  );
}

export default Badge;