import { Loading } from "@kalecard/common";
import firebase from "firebase";
import { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router";
import { onLoginSubmit, onSignUpSubmit, SignUpState } from "../utils/signup";
import SignUpForm, { SignUpInputs } from "../components/forms/SignUpForm";
import { auth } from "../config/firebase";
import SignUpFooter from "./SignUpFooter";
import { useMutation } from "@apollo/client";
import {
  BRAND_USER_SIGN_UP,
} from "../graphql/mutations";
import LoginForm from "../components/forms/LoginForm";

declare global {
  interface Window {
    recaptchaVerifier: any;
    confirmationResult: any;
  }
}

interface SignUpInterface {
  isSignIn: boolean;
  authenticated: boolean;
}

const SignUp = ({ isSignIn, authenticated }: SignUpInterface) => {
  const [state, setState] = useState(
    isSignIn ? SignUpState.LOGIN : SignUpState.SIGNUP
  );
  const [formData, setFormData] = useState(null);
  const locationState: any = useLocation().state;
  const history = useHistory();
  const [error, setError] = useState<string>(null);

  const [brandUserSignUp] = useMutation(BRAND_USER_SIGN_UP);

  const screenName = isSignIn ? "login" : "signup";
  const recaptchaId = "recaptcha-container";

  useEffect(() => {
    setState(isSignIn ? SignUpState.LOGIN : SignUpState.SIGNUP);
  }, [isSignIn]);

  useEffect(() => {
    if (authenticated) {
      if (locationState && locationState.from) {
        history.push(locationState.from.pathname);
      } else {
        history.push("/");
      }
    } else {
      auth.useDeviceLanguage();
      window.recaptchaVerifier = new firebase.auth.RecaptchaVerifier(
        recaptchaId,
        {
          size: "invisible",
          callback: (response) => {
            // reCAPTCHA solved, allow sign in.
            console.log("Captcha callback", response);
          },
        }
      );
    }
    return () => { };
  }, [authenticated]);

  const redirect = async (path: string) => {
    await new Promise(r => setTimeout(r, 500));
    history.push(path);
  }

  let content = null;
  switch (state) {
    case SignUpState.SIGNUP:
      content = (
        <>
          <SignUpForm
            submitForm={(data: SignUpInputs) =>
              onSignUpSubmit(
                screenName,
                data,
                setState,
                setFormData,
                setError,
                redirect,
                brandUserSignUp,
              )
            }
          />
          <SignUpFooter isLogin={false} onButtonClick={() => setError(null)} />
        </>
      );
      break;
    case SignUpState.LOGIN:
      content = (
        <>
          <LoginForm
            submitForm={(data: SignUpInputs) =>
              onLoginSubmit(screenName, data, setState, setFormData, setError, redirect)
            }
          />
          <SignUpFooter isLogin={true} onButtonClick={() => setError(null)} />
        </>
      );
      break;
    case SignUpState.LOADING:
      content = <Loading />;
      break;
    default:
      break;
  }

  if (authenticated) {
    return <Loading />;
  } else {
    return (
      <>
        <div id={recaptchaId}></div>
        <div className="mt-4 w-full">
          {error && (
            <div className="pt-1 text-center text-sm font-bold text-red-700">
              {error}
            </div>
          )}
          <div className="">{content}</div>
        </div>
      </>
    );
  }
};
export default SignUp;
