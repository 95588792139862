export const generateDateRangeOptions = () => {
  const currentDate = new Date();
  const dateRangeOptions = [];

  for (let i = 0; i < 6; i++) {
    const year = currentDate.getFullYear();
    const month = currentDate.getMonth();
    
    const startTime = new Date(year, month, 1).getTime();
    const endTime = new Date(year, month + 1, 0, 23, 59, 59, 999).getTime();

    const label = new Intl.DateTimeFormat('en-US', { year: 'numeric', month: 'short' }).format(new Date(year, month, 1));

    const caption = new Intl.DateTimeFormat('en-US', { month: 'short', day: 'numeric' }).format(new Date(year, month, 1)) + '-' +
                   new Intl.DateTimeFormat('en-US', { month: 'short', day: 'numeric' }).format(new Date(year, month + 1, 0));

    dateRangeOptions.push({ label, value: { startTime, endTime, caption } });

    currentDate.setMonth(month - 1);
  }

  return dateRangeOptions;
}

export const generateLastQuarterAndLastYearOptions = () => {
  const currentDate = new Date();
  const currentYear = currentDate.getFullYear();
  const currentMonth = currentDate.getMonth();
  
  const lastQuarterStart = new Date(currentYear, Math.floor((currentMonth - 3) / 3) * 3, 1);
  const lastQuarterEnd = new Date(currentYear, Math.floor((currentMonth - 3) / 3) * 3 + 3, 0, 23, 59, 59, 999);

  const lastYearStart = new Date(currentYear - 1, 0, 1);
  const lastYearEnd = new Date(currentYear - 1, 11, 31, 23, 59, 59, 999);

  const dateRangeArray = [
    {
      label: "Last quarter",
      value: {
        startTime: lastQuarterStart.getTime(),
        endTime: lastQuarterEnd.getTime(),
        caption: new Intl.DateTimeFormat('en-US', { month: 'short', day: 'numeric' }).format(lastQuarterStart) + '-' +
                new Intl.DateTimeFormat('en-US', { month: 'short', day: 'numeric' }).format(lastQuarterEnd)
      },
    },
    {
      label: "Last year",
      value: {
        startTime: lastYearStart.getTime(),
        endTime: lastYearEnd.getTime(),
        caption: new Intl.DateTimeFormat('en-US', { year: 'numeric', month: 'short', day: 'numeric' }).format(lastYearStart) + '-' +
                new Intl.DateTimeFormat('en-US', { year: 'numeric', month: 'short', day: 'numeric' }).format(lastYearEnd)
      },
    }
  ];

  return dateRangeArray;
}